import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import { RadioButton } from 'primereact/radiobutton';
import { Icon } from '@iconify/react';


function DropFilter({ initClose, handleUpdate, onSumSizesUpdated }) {
    const [selected, setSelected] = useState();
    const [selectedDelete, setSelectedDelete] = useState();
    const [paragraphs, setParagraphs] = useState(0);
    const [alteration, setLevel] = useState('jornalistico');
    const [seo, setSeo] = useState("Otimizar");
    const [language, setLanguage] = useState("Português");


    const handleUpdateParams = () => {
        const selectedSize = selected ? selected.length : 0;
        const selectedDeleteSize = selectedDelete ? selectedDelete.length : 0;
        const sumSizes = selectedSize + selectedDeleteSize;
        onSumSizesUpdated(sumSizes);

        handleUpdate({
            paragraphs,
            alteration,
            seo,
            selected,
            selectedDelete,
            language
        });
        initClose();
    };
    return (
        <>
            <div className="DropFilter h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Row className="">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <h4>Otimizar Escrita <i onClick={initClose}><Icon icon="ep:close-bold" /></i></h4>
                                <p>Selecione os filtros abaixo para otimizar a sua escrita.</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Label className='Label'>Quantidade de parágrafos</Form.Label>
                                <Form.Group className="grup paragrafos">
                                    <Button className={"Btns ButtonLight " + (paragraphs === 0 ? 'active' : '')} onClick={() => setParagraphs(0)} value={0}>Padrão</Button>
                                    <Button className={"Btns ButtonLight " + (paragraphs === 4 ? 'active' : '')} onClick={() => setParagraphs(4)} value={4}>4 parágrafos</Button>
                                    <Button className={"Btns ButtonLight " + (paragraphs === 8 ? 'active' : '')} onClick={() => setParagraphs(8)} value={8}>8 parágrafos</Button>
                                    <Button className={"Btns ButtonLight " + (paragraphs === 12 ? 'active' : '')} onClick={() => setParagraphs(12)} value={12}>12 parágrafos</Button>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Label className='Label '>Tipo de escrita</Form.Label>
                                <Form.Group className="grup nivel">
                                    <Button className={"Btns ButtonLight " + (alteration === 'Simples' ? 'active' : '')} onClick={() => setLevel('Simples')} value={'Simples'}>Simples</Button>
                                    <Button className={"Btns ButtonLight " + (alteration === 'jornalistico' ? 'active' : '')} onClick={() => setLevel('jornalistico')} value={'jornalistico'}>Jornalístico</Button>
                                    <Button className={"Btns ButtonLight " + (alteration === 'Celebridades' ? 'active' : '')} onClick={() => setLevel('Celebridades')} value={'Celebridades'}>Celebridades</Button>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Label className='Label'>Adicione palavras-chave</Form.Label>
                                <Form.Group className="grup">
                                    <TagsInput
                                        value={selected}
                                        onChange={setSelected}
                                        name="palavraChave"
                                        placeHolder="Adicionar"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Label className='Label'>Exclua palavras-chave</Form.Label>
                                <Form.Group className="grup">
                                    <TagsInput
                                        value={selectedDelete}
                                        onChange={setSelectedDelete}
                                        name="excluirPalavra"
                                        placeHolder="Remover"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Label className='Label'>SEO</Form.Label>
                                <Form.Group className="grup seo">
                                    <RadioButton inputId="TitleOption1" name="TitleOption" value="Otimizar" onChange={(e) => setSeo(e.value)} checked={seo === 'Otimizar'} /> <span>Otimizar</span>
                                    <RadioButton inputId="TitleOption1" name="TitleOption" value="Não otimizar" onChange={(e) => setSeo(e.value)} checked={seo === 'Não otimizar'} /> <span>Não otimizar</span>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Label className='Label'>Traduzir</Form.Label>
                                <Form.Group className="grup seo">
                                    <RadioButton inputId="TitleOption1" name="TitleOption" value="Português" onChange={(e) => setLanguage(e.value)} checked={language === 'Português'} /> <span>Português</span>
                                    <RadioButton inputId="TitleOption1" name="TitleOption" value="Inglês" onChange={(e) => setLanguage(e.value)} checked={language === 'Inglês'} /> <span>Inglês</span>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} className="text-center actions">
                                <Button className="Btns ButtonSecondary" onClick={handleUpdateParams}>Aplicar preferências</Button>
                                <Button className="Btns ButtonBack" onClick={initClose}>Voltar</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DropFilter;
