import React from "react";
import Image from './Writer.svg';

import './Image.scss';

function Picture() {

    return (
        <div className="picture">
            <img src={Image} style={{ width: '100%', height: '100%' }} alt="Writer" />
        </div>

    );
}
export default Picture;