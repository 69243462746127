import React from "react";
import Image from './img/img-i7.png';

import './Image.scss';

function DescIntegration() {

    return (
        <div className="picture">
            <img src={Image} style={{ width: '100%', height: '100%' }} alt="Writer" />
            <p><strong>Os benefícios da integração entre i7 e nossa ferramenta de criação de materiais com inteligência artificial</strong></p>
            <p>A integração entre o i7 e nossa ferramenta de criação de materiais com inteligência artificial oferece uma série de benefícios para a sua estratégia de conteúdo. A automação, a melhoria na qualidade, a otimização para mecanismos de busca, a personalização e a análise contínua são apenas alguns dos aspectos positivos dessa combinação. Ao aproveitar essas vantagens, você estará à frente da concorrência, criando conteúdo eficiente e envolvente que atrai e retém a atenção dos seus usuários.</p>
        </div>

    );
}
export default DescIntegration;