import React from "react";
import { Icon } from "@iconify/react";
import  './ShowPassword.scss'

function ShowPassword({ isVisible, toggleVisibility }) {
    return (
      <span className="show-password" onClick={toggleVisibility}>
        <Icon className="preview-password" icon={isVisible ? "carbon:view" : "carbon:view-off"} />
      </span>
    );
  }
  export default ShowPassword;
  