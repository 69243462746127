import React, { useEffect, useState, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import { RadioButton } from 'primereact/radiobutton';
import { Editor } from 'primereact/editor';
import { Icon } from '@iconify/react';


function EditorPostComponent({ onData, onPostChange, fontSize  }) {
    const [isActiveTitleOptions, setActiveTitleOptions] = useState(false);
    const [title, setTitle] = useState(onData?.title_editor || '');
    const [text, setText] = useState(onData?.text_chatgpt || '');
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [review, setReview] = useState(onData?.review || '');
    const [lide, setLide] = useState(onData?.lide_chatgpt || '');

    //console.log(onData);


    const handleTitle = (e) => {
        setTitle(e.value);
        onPostChange({ title_editor: e.value, text_editor: text, lide_editor: lide });
    };

    useEffect(() => {
        if (onData && Object.keys(onData).length > 0) {

            const titleEditor = onData?.title_editor;
            const titleChatGPT = onData?.title_chatgpt && onData.title_chatgpt.length > 0 ? onData.title_chatgpt[0] : '';
            setTitle(titleEditor || titleChatGPT || '');

            const textEditor = onData?.text_editor;
            const textChatGPT = onData?.text_chatgpt;
            setText(textEditor || textChatGPT || '');

            const lideEditor = onData?.lide_editor;
            const lideChatGPT = onData?.lide_chatgpt;
            setLide(lideEditor || lideChatGPT || '');

            setReview(onData?.review );
            setInitialDataLoaded(true);

        }
    }, [onData]);


    const onTextChange = (e) => {
        const newText = e.htmlValue;
        setText(newText);
        onPostChange({ title_editor: title, text_editor: newText, lide_editor: lide });  // Use newText instead of text
    };

    const onLideChange = (e) => {
        const newLide = e.htmlValue;
        setLide(newLide);
        onPostChange({ lide_editor: newLide, title_editor: title, text_editor: text });  // Use newText instead of text
    };


    const renderHeader = () => {
        return (
            <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
      </span>
        );
    };

    const header = renderHeader();

    const toggleTitleOptions = () => {
        setActiveTitleOptions(!isActiveTitleOptions);
    };

    return (
        <>
            <div className="Editor">
                <Form>
                    <div className="EditorTitle">
                        <div className="titlePrimary" style={{width: review === 'approved' || review === 'pending' ? '100%' : ''}}>
                            <textarea className="title" type="text" value={title}   onChange={(e) => {
                                setTitle(e.target.value);
                                onPostChange({ title_editor: e.target.value, text_editor: text, lide_editor: lide });
                            }}></textarea>
                        </div>
                        {review === 'fix' || review === 'waiting' ? (
                        <Button className="Btns ButtonSecondary" onClick={toggleTitleOptions}>
                            Opções de títulos
                        </Button>
                        ) : null}
                    </div>

                    {isActiveTitleOptions ? (
                        <div className="TitleOptions">
                            <h1 className="Title titleSecondary">Outros títulos</h1>
                            {onData?.title_chatgpt.map((optionTitle, index) => (
                                <div className="firstOption" key={"TitleOption" + index}>
                                    <label htmlFor={"TitleOption" + index}>
                                        <h3>{optionTitle}</h3>
                                    </label>
                                    <RadioButton
                                        inputId={"TitleOption" + index}
                                        name="TitleOption"
                                        value={optionTitle}
                                        onChange={(e) => handleTitle(e)}
                                        checked={title === optionTitle}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {lide ? (
                    <textarea className="lide" type="text" value={lide} onChange={(e) => {
                                setLide(e.target.value);
                                onPostChange({ title_editor: title, text_editor: text, lide_editor: e.target.value });
                    }}></textarea>
                    ) : null}
                    <Editor
                        key={initialDataLoaded ? 'loaded' : 'not-loaded'}
                        className="postContent" 
                        onTextChange={onTextChange}  
                        value={text}
                        headerTemplate={header}
                        style={{ height: '480px', fontSize: fontSize }}
                    />
                </Form>
            </div>
        </>
    );
}

export default EditorPostComponent;
