import React from 'react';
import './Users.scss';

function Users() {
    return (
        <div className="Users">
            <h1>Bem-vindo à página de usuários</h1>
        </div>
    );
}

export default Users;
