import React, { useEffect, useState } from "react";
import "./PostsAmount.scss"
import { Icon } from '@iconify/react';
import api from "../../services/api";
import { useAuth } from "../../context/useAuth";
import { formatMilenar } from "../../utils/formatFunctions";
import { getLastMonth } from "../../utils/dates";

export default function PostsAmount() {

    const { user, loading, token } = useAuth()
    const [postsData, setPostsData] = useState()

    const [postsAmount, setPostsAmount] = useState(0)
    const [postsPercent, setPostsPercent] = useState(0)
    const [postsLastMonth, setPostsLastMonth] = useState(0)

    async function getPostsData() {
        await api.get(`/posts`,
            { withCredentials: true }
        ).then((res) => {
            try {
                setPostsAmount(formatMilenar(res.data.currentMonth) )
                setPostsPercent(res.data.percent)
                setPostsLastMonth(formatMilenar(res.data.lastMonth))
            } catch (err) {
                throw err
            }

        })
    }

    useEffect(() => {
        if (user) {
            getPostsData()
        }
    }, [loading,])


    const PercentData = () => {
        return (<>
            <div className="percent-values">
                <span>{postsPercent ? postsPercent : 0}%</span>
            </div>

            <div className="icon-arrowUp">
                <Icon color={"#fff"} icon={"formkit:arrowup"} width={20} height={20}></Icon>
            </div>
        </>)
    }

    return (

        <>
            <div className="widget-postsAmount">
                <div className="box">
                    <div className="content-postsAmount">
                        <div className="icon-pa">
                            <div className="box">
                                <Icon color={"#0092C9"} icon={"mdi:paper-outline"} width={45} height={45}></Icon>
                            </div>
                        </div>
                        <div className="info">
                            <div className="title">
                                <span>Quantidade de matérias</span>
                            </div>
                            <div className="values">
                                <div className="percent">
                                    {
                                        postsPercent > 0 ? <PercentData />
                                            :
                                            postsPercent < 0 ? <PercentData />
                                                :
                                                <></>
                                    }

                                </div>
                                <div className="amount">
                                    {/* 10.456 */}
                                    {postsAmount}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="created-by-month">
                        <div className="values">
                            <span>
                                <strong>
                                    {postsLastMonth}
                                </strong> em {getLastMonth()}
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}