
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/api";
import "./Porcentage.scss";

function Porcentage({ onData, onPostChange, handlePercent, handlePercentAverage }) {

    const { user } = useAuth()

    const [goalPercent, setGoalPercent] = useState()

    async function getAccountGoal() {
        try {
            await api.get(`/settings/${user?.account}`, {
                withCredentials: true
            }).then((res) => {
                setGoalPercent(res.data.meta_for_posts)
                handlePercentAverage(res.data.meta_for_posts)
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (user) {
            getAccountGoal()
        }
    }, [user])

    const [difference, setDifference] = useState(0);

    const getDifference = ({ textEditor, textChatgpt }) => {
        const textChatGptSplit = textChatgpt.split(/\s+/).filter(word => word.length > 0);
        const textEditorSplit = textEditor.split(/\s+/).filter(word => word.length > 0);

        const chatGptWords = new Set(textChatGptSplit);
        const editorWords = new Set(textEditorSplit);

        const differenceBetweenTexts = Array.from(editorWords).filter(word => !chatGptWords.has(word)).length;

        const differenceBetweenTextsPercentage = Math.ceil((differenceBetweenTexts / editorWords.size) * 100);
        onPostChange({ difference_between_text: differenceBetweenTextsPercentage });

        return differenceBetweenTextsPercentage;
    }

    useEffect(() => {
        if (Object.keys(onData).length > 0 && onData?.text_editor && onData?.text_chatgpt) {
            setDifference(getDifference({ textEditor: onData?.text_editor, textChatgpt: onData?.text_chatgpt }));
            handlePercent(difference);
        }
    }, [
        onData?.text_chatgpt,
        onData?.text_editor,
        onData?.difference_between_text,
    ]);
    return (
        <>
            <div className="Porcentage">
                <label>Porcentagem de alteração</label>
                <div className="PercentageChange">
                    <div className="PercentageBar">
                        <span className="bar" style={{ width: `${goalPercent}%` }}></span>
                        <span className="progress warning" style={{ width: `${difference}%` }}></span>
                    </div>
                    <div className="PercentageNumber">{difference} %</div>
                </div>
            </div>
        </>
    );
}

export default Porcentage;
