import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { Icon } from '@iconify/react';

//components
import Navbar from '../../../components/Navbar';
import Metrics from '../../../components/NewPost/Metrics';
import Words from '../../../components/NewPost/Words';
import Porcentage from '../../../components/NewPost/Porcentage';
import EditorPostComponent from '../../../components/NewPost/EditorPost';
import Original from '../../../components/NewPost/Original';
import Feedback from '../../../components/NewPost/Feedback';
import Chronometer from '../../../components/NewPost/Chronometer';
import Alerts from '../../../components/Alerts';
import FontSize from '../../../components/NewPost/FontSize';

//styles
import '../NewPost.scss';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import api from "../../../services/api";
import { useAuth } from "../../../context/useAuth";
import PromptRemake from "../../../components/PromptRemake";

function ResultPost() {
    const { id } = useParams(); // ID do post que será editado
    const [post, setPost] = useState({});
    const [isStatusFeedback, setStatusFeedback] = useState(false)
    const [percent, setPercent] = useState(0)
    const [percentAverage, setPercentAverage] = useState(0)
    const { isNotRedator, isRedator } = useAuth()
    const [isOptions, setOptions] = useState(false)
    const [isDisplay, setDisplay] = useState(false)

    let firstText = ``



    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');
    const [isCountdownEnded, setCountdownEnded] = useState(false)

    const [publishButton, setPublishButton] = useState(<></>)
    const [revisionButton, setRevisionButton] = useState(<></>)

    const [fontSize, setFontSize] = useState(16);


    const [pCount,setPcount] = useState(0);
    const [h2Count,setH2count] = useState(0);

    useEffect(() => {
        if (
            (post.review === 'fix'
                || post.review === 'waiting'
                || post.review === 'pending')
            && (percent >= percentAverage
                && isRedator()
                && isCountdownEnded)
        ) {
            setRevisionButton(<Button className="Btns ButtonPublic" onClick={requestRevision}>Solicitar revisão</Button>)
        } else {
            setRevisionButton(<></>)
        }

        if (
            (post.review === 'fix'
                || post.review === 'waiting'
                || post.review === 'pending')
            && (percent >= percentAverage
                && isNotRedator()
                && isCountdownEnded)
        ) {
            setPublishButton(<Button className="Btns ButtonPublic" onClick={requestPublish}>Publicar</Button>)
        } else {
            setPublishButton(<></>)
        }

    }, [post, isCountdownEnded])

    const handlePost = (newData) => {
        setPost(prevPost => ({ ...prevPost, ...newData }));
    }

    const handlePercent = (value) => {
        setPercent(value)
    }
    const handlePercentAverage = (value) => {
        setPercentAverage(value)
    }

    function countTags(texto, tagName) {
        const pattern = new RegExp(`<${tagName}\\b[^>]*>`, 'g');
        const matches = texto.match(pattern);
        return matches ? matches.length : 0;
      }

    const getPost = async () => {
        await api.get(`/posts/${id}`, {
            withCredentials: true

        }).then(response => {

            if (response.data.comments) {
                setStatusFeedback(true)
            }

            handlePost(response.data)

            firstText = response.data.text_chatgpt

            const pCount = countTags(firstText,'p')
            const h2Count = countTags(firstText,'h2')

            setPcount(pCount)
            setH2count(h2Count)

            console.log(firstText);
        }).catch(error => console.error('Erro na requisição:', error));
    }

    useEffect(()=>{
        console.log("<p>",pCount)
    },[pCount])
    useEffect(()=>{
        console.log("<h2>",h2Count)
    },[h2Count])

    useEffect(() => {
        if (Object.keys(post).length === 0) {
            getPost()
        }
    }, [post])

    const [isActiveOriginal, setActiveOriginal] = useState(false);
    const [isActiveNew, setActiveNew] = useState(true);
    const [isActiveRemake, setActiveRemake] = useState(false);


    const toggleOriginal = () => {
        setActiveOriginal(!isActiveOriginal);
        setActiveNew(!isActiveNew);
    };
    const toggleRemake = async () => {
        setActiveRemake(true);
        console.log("[TOGGLE_RMK]",post)

        await api.post(`/posts/rewrite`, {
            post:post
        }, {
            withCredentials: true,
        }).then(r=>{
            console.log(r.data.rewritedText)
            handlePost({text_chatgpt:r.data.rewritedText})
            setActiveRemake(false);

            const pCount = countTags( r.data.rewritedText,'p')
            const h2Count = countTags( r.data.rewritedText,'h2')

            setPcount(pCount)
            setH2count(h2Count)
        })
    };

    const toggleNew = () => {
        setActiveNew(!isActiveNew);
        setActiveOriginal(!isActiveOriginal);
    };

    const toggleOptions = () => {
        setOptions(!isOptions);
    };

    const toggleDisplay = () => {
        setDisplay(!isDisplay);
    };



    const saveState = async () => {
        try {
            await api.put(`/posts/${post._id}`, post, {
                withCredentials: true
            }).then((res) => {
                alert("Edição salva com sucesso")
            })
        } catch (err) {
            throw err
        }

    }

    const requestRevision = async () => {
        try {
            post.review = "pending"
            await api.put(`/posts/${post._id}`, post, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setType('success');
                setTitle('Revisão solicitada com sucesso!');
                setDescription('Sua matéria foi enviada para revisão');
            })
        } catch (err) {
            throw err
        }

    }

    const requestPublish = async () => {
        try {
            post.review = "approved"
            await api.put(`/posts/${post._id}`, post, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setPublishButton(false)
                setType('success');
                setTitle('Publicado com sucesso!');
                setDescription('Sua matéria foi aprovada');
            })
        } catch (err) {
            throw err
        }
    }


    const savePublish = async () => {
        console.log(post)
        try {
            await api.put(`/posts/${post._id}`, post, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setType('success');
                setTitle('Salvo com sucesso!');
                setDescription('Sua matéria foi salva');
            })
        } catch (err) {
            throw err
        }
    }

    const handleIncreaseFontSize = () => {
        setFontSize((prevSize) => {
            const newSize = prevSize + 1;
            return newSize <= 18 ? newSize : prevSize;
        });
    };

    const handleDecreaseFontSize = () => {
        setFontSize((prevSize) => {
            const newSize = prevSize - 1;
            return newSize >= 12 ? newSize : prevSize;
        });
    };


    return (
        <>
            <Navbar />
            <div className="Content ResultPost">
                {isVisible &&
                    <Alerts title={isTitle} description={initDescription} type={initType} />
                }
                {isStatusFeedback ?
                    <Feedback post={post} />
                    : null}
                <Row>
                    <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                        <Button className={`Tab TabPrimary ${isActiveOriginal ? 'active' : null}`} onClick={toggleOriginal}>Fonte jornalística</Button>
                        <Button className={`Tab TabPrimary ${isActiveNew ? 'active' : null}`} onClick={toggleNew}>Esboço</Button>
                        <div className="ResultEditor">
                            <Row>
                                <Col xs={12} sm={10} md={8} lg={9} className='mx-auto'>

                                    {isActiveNew ? <EditorPostComponent onData={post} onPostChange={handlePost} fontSize={fontSize} /> : null}
                                    {isActiveOriginal ? <Original onData={post} /> : null}
                                </Col>
                                <Col xs={12} sm={10} md={8} lg={3} className='mx-auto'>
                                    <div className="Display">


                                        <i onClick={toggleOptions}><Icon icon="ep:close-bold" /></i>


                                        {post.review === 'fix' || post.review === 'pending' || post.review === 'waiting' ?
                                            <Button className="Btns ButtonEdit" onClick={savePublish}>Salvar</Button> : null}
                                        {/* {post.review === 'fix' || post.review === 'pending' || post.review === 'waiting' ?
                                                <Button className="Btns ButtonLight">Corrigir gramática</Button> : null} */}
                                       
                                        {pCount < 9 || h2Count < 2 ?<Button className="Btns ButtonRemake" onClick={() => { toggleRemake() }}>Refazer</Button>:<></>}

                                        <div className="elementos" style={{ height: `${isDisplay == true ? 'auto' : ''}` }}>
                                            <FontSize onIncreaseFontSize={handleIncreaseFontSize} onDecreaseFontSize={handleDecreaseFontSize} />

                                            <Porcentage
                                                onData={post}
                                                onPostChange={handlePost}
                                                handlePercent={handlePercent}
                                                handlePercentAverage={handlePercentAverage}
                                            />
                                            {post.review === 'waiting' ?
                                                <Chronometer endCountdown={() => { setCountdownEnded(true) }} />
                                                : null}

                                            <Metrics onData={post} />
                                            <Words onData={post} />

                                            <Button onClick={toggleDisplay} className={`Tab BtnHide ${isDisplay == true ? '' : 'open'}`} ><Icon className="icons" icon="mingcute:down-line" /></Button>
                                        </div>

                                        {revisionButton}
                                        {publishButton}

                                        {!isMobile &&
                                            <a href="/my-articles"><Button className="Btns ButtonBack">Voltar</Button></a>
                                        }

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <PromptRemake open={isActiveRemake}  />
        </>
    );
}

export default ResultPost;

