import React, {useEffect, useState} from 'react';
import './ApprovedArticles.scss';
import { Icon } from '@iconify/react';
import {useAuth} from "../../context/useAuth";
import api from "../../services/api";

function ApprovedArticles() {
    const { user, loading, token } = useAuth()
    const [postsApproved, setPostsApproved] = useState(0)
    async function getPostsApproved() {
        await api.get(`/posts/status/approved`,
            {withCredentials:true}
        ).then((res) => {
            try {
                setPostsApproved(res.data.currentMonth)
            } catch (err) {
                throw err
            }
        })
    }
    useEffect(() => {
        if (user) {
            getPostsApproved()
        }
    }, [loading,])
    return (
        <div className='approvedArticles'>
            <div className='icon'>
                <Icon className="aim-icon" color="#0092C9" width={28} height={28} icon="el:check" />
            </div>
            <div className='approvedArticlesText'>
                <h6>Matérias aprovadas</h6>
                <span>{postsApproved}</span>
            </div>
        </div>
    );
}

export default ApprovedArticles;