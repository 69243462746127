
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';

function CreatePost( {initloaddingToWrite, handleDataUpdateWrite } ) {
    const [who, setWho] = useState("");
    const [where, setWhere] = useState("");
    const [when, setWhen] = useState("");
    const [why, setWhy] = useState("");
    const [font, setFont] = useState("");
    const [language, setLanguage] = useState("");
    const [tonality, setTonality] = useState("");

    useEffect(() => {
        if(handleDataUpdateWrite) {
            // console.log('handleDataUpdateWrite') // não está entrando aqui
            handleDataUpdateWrite({
                who,
                where,
                when,
                why,
                font,
                language,
                tonality,
            });
        }
    }, [who, where, when, why, font, language, tonality]);

    return (
        <>
            <Form className="CreatePost">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={12}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="ph:link-bold" />
                            <Form.Control className='Inputs' type="text" onChange={ e => setWho(e.target.value) } placeholder="Quem?" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="mdi:city-variant-outline" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setWhere(e.target.value) } placeholder="Onde?" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="ph:timer-bold" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setWhen(e.target.value) } placeholder="Quando?" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={12}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="material-symbols:emoji-objects-outline" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setWhy(e.target.value) } placeholder="Por que?" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={12}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="dashicons:admin-site" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setFont(e.target.value) } placeholder="Fonte:" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="bi:type" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setLanguage(e.target.value) } placeholder="Língua" />
                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Form.Group className="GroupIcon mb-3">
                            <Icon id="lock" icon="streamline:programming-script-2-language-programming-code" />
                            <Form.Control className='Inputs' type="text"  onChange={ e => setTonality(e.target.value) } placeholder="Tonalidade" />
                        </Form.Group>
                    </Col>
                </Row>
                <Button className="Btns ButtonPrimary" onClick={initloaddingToWrite} >Escrever</Button>
            </Form>
        </>
    );
}

export default CreatePost;
