import React from "react";
import { Col, Row } from "react-bootstrap";

import Navbar from "../../Navbar";
import Image from '../Image';
import { FormCreate } from '../Form';


import './NewAccount.scss';


function NewAccount() {

    return (
        <>
            <Navbar />
            <div className="Content NewAccount">
            <Row>
                    <div className="content">
                    <Col xs={12} sm={12} md={12} lg={12} className='mx-auto custom'>
                        <div className="Title titlePrimary">
                            Novo Publishers
                        </div>

                    </Col>
                    </div>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} className='mx-auto'>
                        <Image />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} className="mx-auto allign-content">
                        <FormCreate/>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default NewAccount;