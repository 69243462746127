
import React, {useEffect, useState} from "react";
import { Button, Col,  Row } from "react-bootstrap";

function PostOriginal({onData}) {
    const [postTitleOriginal, setPostTitleOriginal] = useState();
    const [postTextOriginal, setPostTextOriginal] = useState();
    const [fonte, setFonte] = useState('');
    useEffect(()=>{
        if (onData) {
            setPostTitleOriginal(onData?.original_title)
            setPostTextOriginal(onData?.original_text)
            setFonte(getFirstSiteName(onData?.original_url));
        }
    },[onData])

    const getFirstSiteName = (url) => {
        try {
          const parsedUrl = new URL(url);
          const hostname = parsedUrl.hostname;
          const siteName = hostname.split('.')[0];
          return siteName;
        } catch (error) {
          console.error("Error parsing URL:", error);
          return '';
        }
      };

    return (
        <>
           <div className="Posts">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                        <div className="Review">
                            <Row>
                                <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                    <div className="ReviewTitle">
                                        <Row>
                                            <Col xs={12} sm={10} md={8} lg={7} className='mx-auto'> 
                                            <ul>
                                                <li>Fonte: <strong>{fonte}</strong></li>
                                            </ul>
                                                
                                            </Col>
                                            <Col xs={12} sm={10} md={8} lg={5} className='ReviewActions'>
                                                <a href="/my-articles"><Button className="Btns ButtonLight">Voltar</Button></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> 
                                <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                    <div className="PostView">
                                        <h2>{postTitleOriginal}</h2>
                                        <div className="text">
                                            <p>
                                                {postTextOriginal}
                                            </p>
                                        </div>
                                    </div>
                                </Col> 
                            </Row>
                            <Row>
                                <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                </Col> 
                            </Row>
                        </div>
                    </Col> 
                </Row>
            </div>
        </>
    );
}

export default PostOriginal;
