
import React, {useState} from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { useAuth } from '../../context/useAuth';
import api from "../../services/api";

// components
import Navbar from '../../components/Navbar';
import Company from '../../components/Integrations/Company';
import I7 from '../../components/IntegrationsI7/Company';
import ConfigIntegrations from '../../components/Integrations/ConfigIntegrations';
import ConfigIntegrationsI7 from '../../components/IntegrationsI7/ConfigIntegrations';

// styles
import './Integrations.scss';
import Alerts from "../../components/Alerts";

function Integrations() {
    const { isAdmin, user, updateUser } = useAuth();
    const [isConfig, setConfig]= useState(false);
    const [isActiveConfigWp, setActiveConfigWp]= useState(false);
    const [isActiveConfigI7, setActiveConfigi7]= useState(false);

    const onConfig = () => {
        setConfig(true);
        setActiveConfigWp(true);
        setActiveConfigi7(false);
    };
    const onConfigI7 = () => {
        setConfig(true);
        setActiveConfigWp(false);
        setActiveConfigi7(true);
    };
    return (
        <>
            <Navbar />
            {!isConfig ?
            <div className="Content Config">
                <Row>
                    <Col xs={12} sm={12} md={8} lg={12} >
                        <div className="Title titlePrimary">
                            Integrações
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={3} className='company'>
                        <Company onConfig={onConfig} />
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={3} className='company'>
                        <I7 onConfig={onConfigI7} />
                    </Col>
                </Row>
            </div>
            : null}
            {isActiveConfigWp ?
                <ConfigIntegrations />
            : null}

            {isActiveConfigI7 ?
                <ConfigIntegrationsI7 />
            : null} 
        </>
    );
}

export default Integrations;