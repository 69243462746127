
import React, { useEffect, useState } from "react";

function Metrics({ onData }) {
    const [paragraphs, setParagraphs] = useState(0);
    const [words, setWords] = useState(0);
    const [firstTime, setFirstTime] = useState(true)

    console.log(onData?.alteration);

    useEffect(() => {
        if (Object.keys(onData).length > 0 && onData?.text_editor) {


            let paragraphsCount = onData.text_editor.replaceAll("<p>", "").replaceAll("<br></p>", "|PARAGRAPH|").split('|PARAGRAPH|');

            setParagraphs(paragraphsCount.length);

            let wordsCount = onData.text_editor.split(/\s+/);
            wordsCount = wordsCount.filter(word => word.length > 0);
            setWords(wordsCount.length);
        }


    }, [onData?.alteration, onData?.text_editor]);

    useEffect(() => {

        if (firstTime) {
            // console.log(onData)
            let paragraphsCount = ''
            if (!onData?.text_editor && onData?.text_chatgpt) {

                paragraphsCount = onData?.text_chatgpt.split('<br/>');
                setParagraphs(paragraphsCount.length);

                let wordsCount = onData.text_chatgpt.split(/\s+/);
                wordsCount = wordsCount.filter(word => word.length > 0);
                setWords(wordsCount.length);
    
                setFirstTime(false)
            }

           
        }

    }, [onData])

    return (
        <>
            <div className="Metrics">
                <ul className="list">
                    <li>Tipo de escrita <span>{onData.alteration == "jornalistico" ? "Jornalístico" : onData.alteration == undefined ? "Simples" : onData.alteration}</span></li>
                    <li>Quantidade de parágrafos <span>{paragraphs}</span></li>
                    <li>Quantidade de palavras <span>{words}</span></li>
                </ul>
            </div>
        </>
    );
}

export default Metrics;
