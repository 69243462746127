import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { CustomerService } from './service/CustomerService';
import api from '../../../services/api';

import { CardRowContent } from "./CardRow"

//styles
import './PostsList.scss';
import { useAuth } from '../../../context/useAuth';

function PostsList() {


    const { user, loading, token } = useAuth()
    const [postsData, setPostsData] = useState()
    const [postsNot, setPostsNot] = useState(false)


    const [customers, setCustomers] = useState([]);

    async function getPostsData() {
        await api.get(`/posts/list/pending`,
            { withCredentials: true }
        ).then((res) => {
            try {
                
                let pending = []
                res.data.forEach((post) => {
                    pending.push({
                        content: <CardRowContent
                            title={post.title_editor}
                            content={post.text_editor}
                            id={post.id}
                        />
                    })
                    
                })
                if(pending.length === 0){
                    setPostsNot(true)
                }
                

                setCustomers(pending)

            } catch (err) {
                throw err
            }

        })
    }

    useEffect(() => {
        if (user) {
            getPostsData()
        }
    }, [loading])



    // useEffect(() => {
    //     CustomerService.getCustomersMedium().then((data) => setCustomers(data));
    // }, []);


    return (
        <div className="PostsList">
            <div className='HeaderTitle'>
                <span>Matérias a serem aprovadas</span>
            </div>
            {postsNot &&
                <div className='not-post'>
                    <p>Nenhuma matéria aguardando aprovação no momento.</p>
                </div>
            }
            <DataTable value={customers}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: '50rem' }}
                paginatorTemplate=""
                currentPageReportTemplate="">
                <Column field="content" style={{ width: '100%' }}></Column>
            </DataTable>
            <div className='see-more'>
                <a href="/my-articles" className={"Btns ButtonLight"} >Veja todas</a>
            </div>
        </div>
    );
}
export default PostsList;
