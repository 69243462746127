import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../services/api";
import { useAuth } from "../../context/useAuth";
import Alerts from "../Alerts";
import { useNavigate } from "react-router-dom";

function Modals({isActiveModal, handleClose, title}) {

    const navigate = useNavigate()

    const [post, setPost] = useState()
    const { user, loading, token } = useAuth()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const handleReviewNextPost = async () => {
    setIsVisible(false);
        await api.get(`posts/next/pending/account/${user.account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                let endpoint = "";
                setPost(res.data)
                endpoint =  `/reviewposts/${res.data._id}`;
                window.location.href = window.location.origin + endpoint
            } catch (err) {
              setIsVisible(true);
              setType('success');
              setTitle('Sucesso');
              setDescription('Todas matérias já foram revisadas!');
              setTimeout(() => {
                navigate('/my-articles')
              }, 2000);

  
            }
        })
    }
    return (
        <>
           <Modal className="Modal" show={isActiveModal} onHide={handleClose}>
                {isVisible &&
                     <Alerts title={isTitle} description={initDescription} type={initType} />
                }
                <Modal.Header closeButton className="HeaderTitle">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <a><Button className="Btns ButtonPrimary" onClick={handleReviewNextPost}>Revisar a proxima matéria</Button></a>
                    <a href="/dashboard"><Button className="Btns ButtonLight">Ir para o Dashboard</Button></a>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Modals;