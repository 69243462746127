import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../services/api';
import { Form, Button } from 'react-bootstrap';

function EditPost() {
    const { id } = useParams(); // ID do post que será editado
    const [post, setPost] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(post).length === 0) {
            api.get(`/posts/${id}`)
                .then(response => setPost(response.data))
                .catch(error => console.error('Erro na requisição:', error));

        }
    }, [id]);

    const handleChange = (event) => {
        setPost({ ...post, [event.target.name]: event.target.value });
    };

    const differenceBetweenWords = ({ text_editor, text_chatGpt }) => {
        let words1 = new Set(text_chatGpt.split(/\s+/));
        let words2 = new Set(text_editor.split(/\s+/));

        let intersection = new Set([...words1].filter(word => words2.has(word)));
        let union = new Set([...words1, ...words2]);

        let similarity = (intersection.size / union.size) * 100;
        let difference = 100 - similarity;

        return difference.toFixed(0);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        post.difference_between_text = differenceBetweenWords({
            text_editor: post.text_editor,
            text_chatGpt: post.text_chatgpt
        });

        api.put(`/posts/${id}`, post)
            .then(() => navigate('/posts'))
            .catch(error => console.error('Erro na requisição:', error));
    };

    return (
        <div className="container mt-3">
            <h1>Editar Post</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title">
                    <Form.Label>Título</Form.Label>
                    <Form.Control type="text" name="title" value={post.title || ''} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="text_chatgpt">
                    <Form.Label>Texto ChatGPT</Form.Label>
                    <Form.Control disabled as="textarea" name="text_chatgpt" value={post.text_chatgpt || ''} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="text_editor">
                    <Form.Label>Texto Editor</Form.Label>
                    <Form.Control as="textarea" name="text_editor" value={post.text_editor || ''} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="url">
                    <Form.Label>URL</Form.Label>
                    <Form.Control type="text" name="url" value={post.url || ''} onChange={handleChange} />
                </Form.Group>

                <Form.Group controlId="review">
                    <Form.Label>Revisão</Form.Label>
                    <Form.Control as="select" name="review" value={post.review || ''} onChange={handleChange}>
                        <option value="">Selecione...</option>
                        <option value="pending">Pendente</option>
                        <option value="approved">Aprovado</option>
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit">Salvar</Button>
            </Form>
        </div>
    );
}

export default EditPost;
