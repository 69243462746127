import React, { useEffect, useState } from "react";
import "./PromptRemake.scss"
import { Col } from "react-bootstrap";
import LoadingPost from "../NewPost/LoadingPost";


export default function PromptRemake({ open, toggleRemake }) {



    return (<div className={`prompt-remake ${open ? "active" : ""}`}>
        <div className={`prompt-remake-bg ${open ? "active" : ""}`}></div>
        <div className="prompt-box">
                <LoadingPost/>
        </div>
    </div>)
}