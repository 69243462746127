import React from "react";
import './Button.scss';


function Button() {
        return (
            <div className="page-redator">
                 <a href="/newwriter" className='Btns ButtonPrimary'>NOVO</a>
            </div>
        );
    }

export default Button;