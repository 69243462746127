import React from "react";

export default function AvatarInitials({rowData}) {
    return (
        <>
            <div className={"avatar"}>
                <div className='icon'><span>{rowData.Picture}</span></div>
            </div>
        </>
    )
}