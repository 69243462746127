import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../../context/useAuth";

//components
import Navbar from '../../components/Navbar';
import CreatePost from '../../components/NewPost/CreatePost';
import RewritePost from '../../components/NewPost/RewritePost';
import LoadingPost from '../../components/NewPost/LoadingPost';
import DropFilter from '../../components/NewPost/DropFilter';
import Alerts from "../../components/Alerts";
//styles
import './NewPost.scss';

// services
import api from '../../services/api'


function NewPost() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading, token } = useAuth()
    const [sumSizes, setSumSizes] = useState(0);

    const [isActiveNewPost, setActiveNewPost] = useState(true);
    const [isActiveLoading, setActiveLoading] = useState(false);
    const [isActiveCreatePost, setActiveCreatePost] = useState(false);
    const [isActiveRewritePost, setActiveRewritePost] = useState(true);
    const [isDropFilter, setDropFilter] = useState(false);
    const [inputData, setInputData] = useState({});
    const [inputParams, setInputParams] = useState({});
    const [urlPost, setUrlPost] = useState('');
    const [textPostTextarea, setTextPostTextarea] = useState('');
    const [inpuDataWrite, setInputDataWrite] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');
    const [loadingContent, setLoadingContent] = useState(
        isActiveLoading &&
        <Col xs={12} sm={10} md={8} lg={7} className='mx-auto'>
            <LoadingPost />
        </Col>
    )


    useEffect(() => {
        setUrlPost(location.state?.urlPost || '');
        setTextPostTextarea(textPostTextarea)
    }, [location.state?.urlPost, textPostTextarea]);

    useEffect(() => {
        console.log("isActiveLoading", isActiveLoading)
        setLoadingContent(isActiveLoading &&
            <Col xs={12} sm={10} md={8} lg={7} className='mx-auto'>
                <LoadingPost />
            </Col>
        )
    }, [isActiveLoading])

    const handleDataUpdate = (newData) => {
        setInputData(newData);
    };

    const handleUpdateParams = (newData) => {
        setInputParams(newData);
    }

    const handleDataUpdateWrite = (newData) => {
        setInputDataWrite(newData);
    }

    const toggleCreatePost = () => {
        setActiveCreatePost(!isActiveCreatePost);
        setActiveRewritePost(!isActiveRewritePost);
    };

    const toggleRewritePost = () => {
        setActiveRewritePost(!isActiveRewritePost);
        setActiveCreatePost(!isActiveCreatePost);
    };

    const initloadding = () => {
        setActiveLoading(true)
        setActiveNewPost(false)
        api.post(!inputData.url ? '/posts/add-by-text' : '/posts/add-by-url', { ...inputData, ...inputParams, id_user: user.id, id_account: user.account }, { withCredentials: true }).then(response => {
            if (!response.data?.text_chatgpt) {
                setIsVisible(true)
                setType('danger');
                setTitle('Por favor, tente novamente.');
                setDescription('Tivemos um problema com a conexão');
                setActiveNewPost(true)
                setActiveLoading(false)
            } else {
                navigate(`/resultpost/${response.data._id}`, { state: { data: response.data } });
            }

            setActiveLoading(false)
        }).catch(error => {
            console.log(error)
            setIsVisible(true)
            setType(error.response.data.error?.status || 'danger');
            setTitle(error.response.data.error?.title || 'Ocorreu um erro!');
            setDescription(error.response.data.error?.data || 'Não foi possível extrair o texto da matéria.');
            setActiveNewPost(true);
            setActiveLoading(false);

            console.log(error)
            console.error('Erro na requisição:', error)
        })
    };

    const initloaddingToWrite = () => {
        setActiveLoading(true)
        setActiveNewPost(false)
        api.post('/posts/write-post', { ...inpuDataWrite, id_user: user.id, id_account: user.account }, { withCredentials: true }).then(response => {
            if (!response.data?.text_chatgpt) {
                setIsVisible(true)
                setType('danger');
                setTitle('Por favor, tente novamente.');
                setDescription('Tivemos um problema com a conexão');
                setActiveNewPost(true);
                setActiveLoading(false);
            } else {
                navigate(`/resultpost/${response.data._id}`, { state: { data: response.data } })
            }
            setActiveLoading(false)
        }).catch(error => {
            setIsVisible(true)
            setType('danger');
            setTitle('Ocorreu um erro!');
            setDescription('Não foi possível extrair o texto da matéria.');
            setActiveLoading(false)
            console.error('Erro na requisição:', error)
        });
    };

    const initClose = () => {
        setDropFilter(false);
    };

    const initOpen = () => {
        setDropFilter(true);
    };

    const handleSumSizesUpdated = (sumSizes) => {
        setSumSizes(sumSizes)
    };


    return (
        <>
            <Navbar />
            <div className="Content NewPost">
                <div className={`ContainerDropFilter ${isDropFilter ? 'active' : 'hide'}`}>
                    <DropFilter initClose={initClose} handleUpdate={handleUpdateParams} onSumSizesUpdated={handleSumSizesUpdated} />
                </div>
                <Container fluid className='h-100'>
                    {isVisible &&
                        <Alerts title={isTitle} description={initDescription} type={initType} />
                    }
                    <Row className="justify-content-center align-items-center h-100 RewritePost">
                        {isActiveNewPost ?
                            <Col xs={12} sm={10} md={8} lg={7} className='mx-auto'>
                                <Button className={`Links LinkPrimary ${isActiveRewritePost ? 'active' : null}`} onClick={toggleRewritePost}>Usar como fonte</Button>
                                <Button className={`Links LinkPrimary ${isActiveCreatePost ? 'active' : null}`} onClick={toggleCreatePost}>Criar matéria</Button>

                                {isActiveRewritePost ? <RewritePost
                                    initloadding={initloadding}
                                    initOpen={initOpen}
                                    onDataUpdate={handleDataUpdate}
                                    sumSizes={sumSizes}
                                    urlPost={urlPost}
                                    textPostTextarea={textPostTextarea}
                                /> : null}
                                {isActiveCreatePost ? <CreatePost
                                    initloaddingToWrite={initloaddingToWrite}
                                    handleDataUpdateWrite={handleDataUpdateWrite}
                                /> : null}

                            </Col>
                            : null}
                        {loadingContent}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default NewPost;
