import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import './index.scss'
import logoBtn from '../../logo-btn.svg'
import logoOrigin from '../../icon-view.png'

const CardPost = ({ title, category, description, img, trending, date, url, total_shares }) => {
    console.log(total_shares)
    const navigate = useNavigate();
    const [urlPost, setUrlPost] = useState(url);
    const navigateToNewPost = ({ url }) => {
        setUrlPost(url);
        navigate(`/newpost`, {state: {urlPost: url}});
    }

    const urlsite = new URL(url);
    var web = urlsite.hostname.replace("www.", "");
    var site = web.split('.')[1];
    var subdominio = web.split('.')[0];
    if(site == "com"){
        var origem = subdominio;
    }
    else{
        var origem = site;
    }


    return (
        <div className='card-container'>
            <div className='card-post'>
                <figure>
                    <div className='category-card'>{origem}</div>
                    <img src={img} />
                </figure>

                <div className="card-date">
                    {(total_shares &&
                        <div className='trending-ranking'>
                            <span>🔥Trending N° {total_shares}</span>
                        </div>) || <div></div>
                    }
                    {date && <span>Há {date} atrás</span>}

                </div>
                <a className="title" href='#post'>{title}</a>
                {/* <p>{description}</p> */}
                <div className="card-actions">
                    <a onClick={() => navigateToNewPost({ url })}><button className='Btns ButtonSecondary'><img src={logoBtn} />Usar como fonte</button></a>
                    <a href={ url } target="_blank"><button className='Btns ButtonLight'><img src={logoOrigin} /></button></a>
                </div>
            </div>
        </div>
    )
}

export default CardPost
