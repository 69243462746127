import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/api";
import { startCountdown } from "../../../utils/dates";
import { Col, Row } from "react-bootstrap";
import "./Chronometer.scss";

export default function Chronometer({ endCountdown }) {

    const { user } = useAuth()
    const [totalSeconds, setTotalSeconds] = useState("00:00:00");
    const [timeString, setTimeString] = useState("00:00:00")
    const [started, setStarted] = useState(true)
    const [finish, setFinish] = useState(false)
    const [firstTime, setFirstTime] = useState(true)
    const [countdownInterval, setCountdownInterval] = useState(null);


    useEffect(() => {

        const timeParts = timeString.split(":");
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const seconds = parseInt(timeParts[2]);
        const calculatedSeconds = hours * 3600 + minutes * 60 + seconds;

        // console.log({
        //     calculatedSeconds,
        //     firstTime,
        //     "calculatedSeconds >= 0 && firstTime": calculatedSeconds >= 0 && firstTime
        // })

        if (calculatedSeconds >= 0 && firstTime) {
            setFirstTime(false)
        }else{
             setTotalSeconds(calculatedSeconds);
        }
       

        const interval = setInterval(() => {
            setTotalSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        setCountdownInterval(interval)

        return () => {
            clearInterval(interval);
        };


    }, [timeString]);

    useEffect(() => {

        if (totalSeconds <= 0) {
            clearInterval(countdownInterval);
            setFinish(true)
        }
    }, [totalSeconds, countdownInterval]);

    useEffect(() => {
        if (finish) {
            endCountdown()
        }
    }, [finish])


    async function getMetaTime() {
        try {
            await api.get(`/settings/${user?.account}`, { withCredentials: true }).then((res) => {

                //setTimeString(res.data[0].time)
                //setTimeString(res.data.time)

                setTimeString(res.data.time?res.data.time:"00:20:00")

                formatTime(totalSeconds)
            })
        } catch (err) {
            throw err
        }
    }

    const formattedTime = formatTime(totalSeconds);


    useEffect(() => {
        if (user) {
            getMetaTime()
        }
    }, [user])

    return (
        <div className="timer">

            <div className="title">
                <span>Tempo de revisão</span>
            </div>
            <div className="counter">
                <span> {formattedTime}</span>
            </div>

        </div>
    )
}

const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedHours = padZero(hours);
    const formattedMinutes = padZero(minutes);
    const formattedSeconds = padZero(seconds);

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
}

const padZero = (number) => {
    return number.toString().padStart(2, "0");
}