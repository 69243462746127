import { React, useEffect, useState, Fragment } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import './List.scss';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/api';
import AvatarInitials from './avatar';
import { Dropdown } from 'primereact/dropdown';

function List({ filter, nameFilter }) {
  const { user, loading, token, getInitials } = useAuth();
  const [users, setUsers] = useState();

  const handleUsers = (values) => {
    setUsers(values);
  };

  async function getPostsData() {
    const roles = await api.get(`/roles`, { withCredentials: true }).then((res) => res.data);

    await api.get(`/users/account/${user?.account}`, { withCredentials: true }).then((res) => {
      try {
        let tmp_users = [];
        res.data.forEach((c_user) => {
          tmp_users.push({
            Id: c_user._id,
            Picture: getInitials(c_user.name),
            Name: c_user.name,
            Email: c_user.email,
            Type: c_user.id_role,
            Status: c_user.active ? 'ativo' : 'inativo',
          });
        });

        tmp_users.forEach((user) => {
          roles.forEach((role) => {
            if (user.Type === role._id) {
              user.Type = {
                name: role.name,
                id: role._id
              };
            }
          });
        });

        let secondaryUsers = []
        tmp_users.forEach((c_user) => {
          if (c_user.Type.name === "admin" && user.role === "admin") {
            secondaryUsers.push(c_user)
          }
          if (c_user.Type.name !== "admin") {
            secondaryUsers.push(c_user)
          }
        });
    
        handleUsers(secondaryUsers);
      } catch (err) {
        throw err;
      }
    });
  }

  useEffect(() => {
    if (user) {
      getPostsData();
    }
  }, [loading]);

  const filteredUsers = users
    ? users.filter((user) => {
        if (filter && nameFilter) {
          return (
            user.Status === filter.toLowerCase() &&
            user.Name.toLowerCase().includes(nameFilter.toLowerCase())
          );
        } else if (filter) {
          return user.Status === filter.toLowerCase();
        } else if (nameFilter) {
          return user.Name.toLowerCase().includes(nameFilter.toLowerCase());
        } else {
          return true;
        }
      })
    : null;

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 6, value: 6 },
        { label: 12, value: 12 },
        { label: 18, value: 18 },
        { label: 24, value: 24 },
        { label: 30, value: 30 },
      ];

      return (
        <Fragment>
          <span className='mx-1' style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Matérias por página:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className='listWriter'>
      <div className='card'>
        <DataTable
          value={filteredUsers}
          paginatorClassName={'paginator'}
          paginatorTemplate={paginatorTemplate}
          sortField={'date'}
          sortOrder={-1}
          paginator
          rows={6}
        >
          <Column field='Id' style={{ width: '0%' }} body={(rowData) => <></>} />
          <Column
            field='Picture'
            bodyClassName='title-column'
            style={{ width: '10%' }}
            body={(rowData) => <AvatarInitials rowData={rowData} />}
          />

          <Column field='Name' header='Nome' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column field='Email' header='E-Mail' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column field='Type.name' header='Tipo' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column
            field='Status'
            header='Status'
            style={{ width: '20%' }}
            body={(rowData) => {
              let statusClass = '';
              if (rowData.Status === 'ativo') {
                statusClass = 'status-ativo';
              } else if (rowData.Status === 'inativo') {
                statusClass = 'status-inativo';
              }
              return (
                <span>
                  <span className={statusClass}></span>&nbsp;
                  <span className='status'>{rowData.Status}</span>
                </span>
              );
            }}
          ></Column>

          <Column
            field='Actions'
            header=''
            style={{ width: '10%' }}
            body={(rowData) => {
              return <a href={`/editwriter?id=${rowData.Id}`} className='edit-button'> EDITAR </a>;
            }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default List;

