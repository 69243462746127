import React, { useState,useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';

import { Icon } from "@iconify/react";

import './StatusSelect.scss';

export default function StatusSelect({ currentSelectedStatus,handleStatusChange }) {
    const [selectedStatus, setSelectedStatus] = useState(currentSelectedStatus)
    const status = [
        { name: 'Ativo' },
        { name: 'Inativo' },
    ];

    useEffect(() => {
        handleStatusChange(selectedStatus)
    }, [selectedStatus])

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={status} optionLabel="name"
                placeholder={currentSelectedStatus ? currentSelectedStatus : <PlaceholderWithImage />} className="w-full md:w-14rem custom-dropdown " />
        </div>
    )
}

function PlaceholderWithImage() {
    return (
        <div className="placeholder-container">
            <Icon id='statusIcon' icon="fluent-mdl2:world" />
            <span className="placeholder-text">Status</span>
        </div>
    );
}