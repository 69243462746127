import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const AddPostModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState("");

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleChange = (e) => setUrl(e.target.value);
    const handleSubmit = () => {
        // Aqui você chamaria a API para adicionar o novo post.
        // Como ainda não temos a URL da API, deixamos um exemplo:
        // fetch("http://localhost:3000/example", { method: "POST", body: JSON.stringify({ url }) })
        handleClose();
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Adicionar Post
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="url" placeholder="Digite a URL" value={url} onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddPostModal;
