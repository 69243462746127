import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmModal = ({show, handleClose, title, text, confirmAction}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={confirmAction}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;
