import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import './Company.scss';
import i7 from './img/i7.png';
import { useAuth } from "../../../context/useAuth";
import api from "../../../services/api";

function Company({onConfig}) {

    const { user, loading } = useAuth()
    const [isSave, setSave] = useState()

    async function getUserData(account) {
        await api.get(`/i7/${account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    if (!res.data[0]) {
                        setSave(false)
                    }else{
                        setSave(true)
                    }
                }
                else{
                    setSave(false)
                }
            } catch (err) {
                throw err
            }
        })
    }

    useEffect(() => {
        if (user) {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);

            getUserData(user.account)

        }
    }, [loading, user])

    return (
        <div className="itemCompany">
             <div className="img">
                <img src={i7} />
             </div>
             <Button className="Btns ButtonPrimary" onClick={onConfig}>
                {!isSave ? "Conectar" : "Alterar"}
            </Button>
        </div>
    );
}
export default Company;