import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import './Form.scss';
import TypeSelect from "../../TypeSelect";
import StatusSelect from "../../StatusSelect";
import Alerts from "../../../../components/Alerts";
import api from "../../../../services/api";
import { useAuth } from "../../../../context/useAuth";

function FormEdit() {

    const navigate = useNavigate()

    const { user, loading } = useAuth()
    const [roleSelector, setRoleSelector] = useState([])
    const [roleList, setRoleList] = useState([])

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [role, setRole] = useState()
    const [status, setStatus] = useState()
    const [isWriter, setIsWriter] = useState(false);

    const handleName = (v_name) => {
        setName(v_name)
    }

    const handleEmail = (v_email) => {
        setEmail(v_email)
    }

    const handleRole = (v_role) => {
        setRole(v_role)
    }

    const handleStatus = (v_status) => {
        setStatus(v_status)
    }

    const handleRoleList = (roles) => {
        let list = []
        roles.forEach((d_role) => {

            if (d_role.name === "admin" && user.role === "admin") {
                list.push({
                    name: d_role.name
                })
            }
            if (d_role.name !== "admin") {
                list.push({
                    name: d_role.name
                })
            }
        })
        setRoleSelector(list)
        setRoleList(roles)
    }

    async function getRoles() {
        await api.get(`/roles`,
            { withCredentials: true }
        ).then(res => handleRoleList(res.data))

            .catch(err =>
                alert('Erro ao carregar os cargos!'))
    }


    async function getUserData(account, id) {

        await api.get(`/users/${id}`,
            { withCredentials: true }
        ).then((res) => {
            try {

                const selectedUser = res.data

                handleEmail(selectedUser.email)
                handleName(selectedUser.name)
                handleStatus(selectedUser.active ? 'Ativo' : 'Inativo')

                roleList.forEach((role) => {
                    if (selectedUser.id_role == role._id) {
                        handleRole(role.name)
                    }
                })

            } catch (err) {
                throw err
            }

        })
    }
    useEffect(() => {
        if (user) {
            getRoles()
        }

    }, [user])

    useEffect(() => {
        if (user && roleList) {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);
            const id = params.get('id');
            getUserData(user.account, id)
        }
    }, [loading, user, roleList])



    const handleUsernameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleRoleChange = (value) => {
        setRole(value);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const getRoleId = () => {
        let id = ""
        roleList.forEach((c_role) => {


            if (typeof role == 'string') {
                if (role == c_role.name) {
                    id = c_role._id
                }
            } else {
                if (role.name == c_role.name) {
                    id = c_role._id
                }
            }
        })
        return id
    }

    const validateEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const handleSubmit = async (event) => {


        const roleId = getRoleId()
        const sendRelationShip = {
            name,
            email,
            active: typeof status == 'string' ? status.toLowerCase() == "ativo" ? true : false : status.name.toLowerCase() == "ativo" ? true : false,
            id_role: roleId
        }

        const InputUser = document.getElementById('userInput');
        const InputMail = document.getElementById('mailInput');
        const iconUser = document.getElementById('userIcon');
        const iconMail = document.getElementById('mailIcon');


        iconUser.classList.remove('iconWrong');
        iconMail.classList.remove('iconWrong');
        InputUser.classList.remove('inputWrong');
        InputMail.classList.remove('inputWrong');

        if (InputUser.value.trim() === '') {
            InputUser.classList.add('inputWrong');
            iconUser.classList.add('iconWrong');
            return;
        }

        if (InputMail.value.trim() === '') {
            InputMail.classList.add('inputWrong');
            iconMail.classList.add('iconWrong');
            return;
        }

        if (!validateEmail(InputMail.value.trim())) {
            InputMail.classList.add('inputWrong');
            iconMail.classList.add('iconWrong');
            return;
        }

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        await api.put(`/users/${userId}`, sendRelationShip, {
            withCredentials: true
        }).then((res) => {
            setIsVisible(true);
            setType('success');
            setTitle('Sucesso');
            setDescription('Edição realizada!');

            setTimeout(() => {
                navigate('/managereports');
            }, 2000);
        })

    };

    return (

        <div className='form'>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <div className="information">
                <span>Verifique e, se necessário, faça alterações nas informações abaixo:</span>
            </div>
            <form>
                <div className="form-group">

                    <span className='icon'>
                        <Icon id='userIcon' icon="ph:user-bold" />
                    </span>
                    <input type="text" className="form-control" id="userInput" placeholder="Nome" value={name}
                        onChange={handleUsernameChange} />

                    <span className='icon'>
                        <Icon id='mailIcon' icon="line-md:email" />
                    </span>
                    <input type="text" className="form-control" id="mailInput" placeholder="E-mail" value={email}
                        onChange={handleEmailChange} />

                    <div className="select">
                        <span className='icon'>
                            <Icon id='typeIcon' icon="fluent-mdl2:world" />
                        </span>
                        <TypeSelect roleSelector={roleSelector} selectedRole={role} handleRoleChange={handleRoleChange} />

                        <span className='icon'>
                            <Icon id='statusIcon' icon="fluent-mdl2:world" />
                        </span>
                        <StatusSelect value={status} currentSelectedStatus={status} handleStatusChange={handleStatusChange} />
                    </div>

                </div>

                <div className="button">
                    <Link to="/managereports" className="btn back">VOLTAR</Link>
                    <input type="button" onClick={handleSubmit} className="btn create" value="SALVAR ALTERAÇÔES"></input>
                </div>

            </form>
        </div>
    );
}
export default FormEdit;
