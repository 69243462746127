import React, {useEffect, useState} from 'react';
import './TotalArticles.scss';
import { Icon } from '@iconify/react';
import api from "../../services/api";
import { useAuth  } from "../../context/useAuth";

function TotalArticles() {
    const { user, loading, token } = useAuth()
    const [postsAmount, setPostsAmount] = useState(0)
    async function getPostsData() {
        await api.get(`/posts`,
            {withCredentials:true}
        ).then((res) => {
            try {
                setPostsAmount(res.data.currentMonth)
            } catch (err) {
                throw err
            }
        })
    }
    useEffect(() => {
        if (user) {
            getPostsData()
        }
    }, [loading,])
    return (
        <div className='totalArticles'>
            <div className='icon'>
                <Icon className="aim-icon" color="#0092C9" width={34} height={34} icon="bxs:edit" />
            </div>
            <div className='totalArticlesText'>
                <h6>Total de matérias</h6>
                <span>{postsAmount}</span>
            </div>
        </div>
    );
}

export default TotalArticles;