import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Icon } from "@iconify/react";

import './TypeSelect.scss';
import { useEffect } from "react";

export default function TypeSelect({ roleSelector, selectedRole, handleRoleChange }) {
   
    const [selectedType, setSelectedType] = useState(selectedRole);
    const types = roleSelector ? roleSelector : [ ];

    useEffect(()=>{
        handleRoleChange(selectedType)
    },[selectedType])

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedType} onChange={(e) => setSelectedType(e.value)} options={types} optionLabel="name"
                placeholder={selectedRole ? selectedRole : <PlaceholderWithImage />} className=" w-full md:w-14rem custom-dropdown " />
        </div>
    )
}

function PlaceholderWithImage() {
    return (
        <div className="placeholder-container">
            <Icon id='typeIcon' icon="fluent-mdl2:world" />
            <span className="placeholder-text">Tipo</span>
        </div>
    );
}

