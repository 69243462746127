import React, {useEffect, useState} from 'react';
import './DisapprovedArticles.scss';
import { Icon } from '@iconify/react';
import {useAuth} from "../../context/useAuth";
import api from "../../services/api";

function DisapprovedArticles() {
    const { user, loading, token } = useAuth()
    const [postsRejected, setPostsRejected] = useState(0)
    async function getPostsDisapproved() {
        await api.get(`/posts/status/rejected`,
            {withCredentials:true}
        ).then((res) => {
            try {
                setPostsRejected(res.data.currentMonth)
            } catch (err) {
                throw err
            }
        })
    }
    useEffect(() => {
        if (user) {
            getPostsDisapproved()
        }
    }, [loading,])
    return (
        <div className='disapprovedArticles'>
            <div className='icon'>
                <Icon className="aim-icon" color="#0092C9" width={30} height={30} icon="fluent:delete-12-filled" />
            </div>
            <div className='disapprovedArticlesText'>
                <h6>Matérias reprovadas</h6>
                <span>{postsRejected}</span>
            </div>
        </div>
    );
}

export default DisapprovedArticles;