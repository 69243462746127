import React, { useContext, useEffect, useState } from 'react';
import "./index.scss"
import Alert from '../Alert';
import logoWhite from '../../logo-white.png'
import { Icon } from '@iconify/react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import api from '../../services/api';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import MonthlyGoal from '../MonthlyGoal';

function Navbar() {

    const location = useLocation();
    const { logout, user, loading, avatar, isAdmin, checkLogin } = useAuth()
    const history = useRoutes
    const [isDelay, setIsDelay] = useState("hide")
    const [goalPercent, setGoalPercent] = useState(0)
    const [profilePicture, setProfilePicture] = useState("AE")
    const [isConfig, setConfig] = useState();
    const [name, setName] = useState("")
    const isPersonify = window.localStorage.getItem('personify-user') || '';

    const getPostsGoal = async () => {
        await api.get(`/goals/account/${user?.account}`, { withCredentials: true }).then((res) => {
            setGoalPercent(res.data.goalPercent)
        })
    }

    useEffect(() => {
        if (user) {
            getPostsGoal()
            setName(user?.nome)
        }
    }, [user])

    useEffect(() => {
        if (loading) {
            checkLogin()
        }
    }, [loading])


    const handleClickThreeDots = () => {
        setConfig(!isConfig)
    }


    const depersonify = () => {
     Cookies.set('Token', isPersonify);
     window.localStorage.setItem('personify-user', '');
     window.location.href = window.location.origin + "/dashboard";
    }
    const handleClickMenu = () => {
        document.querySelector("aside div.container-header").style.left == "0px" ? document.querySelector("aside div.container-header").style.left = "-100%" : document.querySelector("aside div.container-header").style.left = "0px";
    }
    useEffect(() => {
        setTimeout(() => {
            setIsDelay("");
        }, 200);
    }, [])

    return (
        <>
            {isMobile && 
            <header>
                <div className="headerMobile">
                    <a href="/"><img className="logo" src={logoWhite} /></a>
                    <Icon onClick={handleClickMenu} className='menu-hamburger' width="40" height="40" icon="jam:menu" />
                </div>
                {isAdmin() ?
                <>
                    {!location.pathname.includes('/resultpost/') ?
                        <MonthlyGoal></MonthlyGoal>
                    : '' }
                </>
                : <></>}
                {!isAdmin() ?
                    <div className={`user-card meta ${isDelay}`}>
                        <div>
                            <Icon className="aim-icon" color="#43c7ed" icon="mdi:bullseye" />
                            <span className='meta'>Minha meta</span>
                            <span className="percent">{goalPercent}%</span>
                        </div>
                        {/*<progress id="file" value={goalPercent} max="100">{goalPercent}%</progress>*/}
                        <div className="progress"><span style={{ width: `${goalPercent}%` }}></span></div>
                    </div>
                : <></>}
            </header>
            }
            {isMobile && 
                <>
                    {!location.pathname.includes('/resultpost/') ?
                        <div className='atalhos'>
                            <ul>
                                <li>
                                    <a href="/newpost">
                                        <Icon className="icons" icon="ic:round-post-add" />
                                        <span>Nova matéria</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/dashboard">
                                        <Icon className="icons" icon="radix-icons:dashboard" />
                                        <span>Dashboard</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                  : ''}
                </>
            }
            
            <aside>
                
                <div className='container-header'>
                    <a href="/">
                        <img className="logo" src={logoWhite} />
                    </a>
                    <a className='closeMenu' onClick={handleClickMenu}><Icon icon="ep:close-bold" /></a>

                    {!isAdmin() && !isMobile ?
                        <div className={`user-card meta ${isDelay}`}>
                            <div>
                                <Icon className="aim-icon" color="#43c7ed" icon="mdi:bullseye" />
                                <span className='meta'>Minha meta</span>
                                <span className="percent">{goalPercent}%</span>
                            </div>
                            {/*<progress id="file" value={goalPercent} max="100">{goalPercent}%</progress>*/}
                            <div className="progress"><span style={{ width: `${goalPercent}%` }}></span></div>
                        </div>
                        : <></>}
                    <hr />

                    <nav className="navbar">

                        <ul className="navbar-nav">
                            <li className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                                <a href="/dashboard">
                                    <Icon className="icons" icon="radix-icons:dashboard" />
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li className={`nav-item ${location.pathname === '/newpost' ? 'active' : ''}`}>
                                <a href="/newpost">
                                    <Icon className="icons" icon="ic:round-post-add" />
                                    <span>Nova matéria</span>
                                </a>
                            </li>
                            <li className={`nav-item ${location.pathname === '/my-articles' ? 'active' : ''}`}>
                                <a href="/my-articles">
                                    <Icon className="icons" icon="ic:round-post-add" />
                                    <span>Minhas matérias</span>

                                </a>
                            </li>
                            {isAdmin() ?
                                <li className={`nav-item ${location.pathname === '/managereports' ? 'active' : ''}`}>
                                    <a href="/managereports">

                                        <Icon className="icons" icon="bxs:report" />
                                        <span>Gerenciar redatores</span>
                                    </a>
                                </li>
                            : <></>}
                            {user?.role === "admin" ?
                                <li className={`nav-item ${location.pathname === '/accounts' ? 'active' : ''}`}>
                                    <a href="/accounts">

                                        <Icon className="icons" icon="mdi:accounts-outline" />
                                        <span>Gerenciar contas</span>
                                    </a>
                                </li>
                            : <></>}
                              {isAdmin() ?
                                <li className={`nav-item ${location.pathname === '/integrations' ? 'active' : ''}`}>
                                    <a href="/integrations">

                                        <Icon className="icons" icon="icon-park-outline:circular-connection" />
                                        <span>Integrações</span>
                                    </a>
                                </li>
                            : <></>}
                        </ul>
                    </nav>
                    <div className="user-card profile" onClick={handleClickThreeDots}>
                        <div className="profile">

                            <a className='avatar' href="#profile">{avatar}</a>

                            <a href="#profile">
                                <span>
                                    {`${name.split(' ')[0]} ${name.split(' ').length > 1 && name.length > 12 ? '' : name.split(' ')[1] == undefined ? '' : name.split(' ')[1] }`}
                                </span>
                            </a>

                            <Icon className="icons" icon="ph:dots-three-vertical-bold" />
                        </div>
                        {isConfig ?
                            <div className="config">
                                <ul>
                                    <li><a href="/config"><i><Icon className="icons" icon="grommet-icons:configure" /></i> Configurações</a></li>
                                    {isPersonify ?
                                        <li onClick={depersonify}><a><i><Icon className="icons" icon="iconamoon:enter-fill"/></i> Despersonificar</a></li>
                                        : null
                                    }
                                    <li onClick={logout}><a><i><Icon className="icons" icon="uil:signout" /></i> Sair</a></li>
                                </ul>
                            </div>
                        : null}
                    </div>
                </div>
            </aside>
            {!isMobile && 
             <Alert />
            }
        </>
    );
}
export default Navbar;
