import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import jwt from "jwt-decode"

export default function PrivateRoute({ component: Component, ...rest }) {
    const [cookie, setCookie] = useCookies(["Token"])
    const { Token } = cookie
    return Token ? <Outlet /> : <Navigate to={"/login"}></Navigate>;
};
