import React, {useEffect, useState} from "react";
import { Button, Col, Row } from "react-bootstrap";

//components
import Navbar from '../../components/Navbar';
import PostOriginal from '../../components/RewiewPosts/PostOriginal';
import ViewPost from '../../components/RewiewPosts/ViewPost';
import ReportComments from '../../components/RewiewPosts/ReportComments';
import Modals from '../../components/Modals';

//styles
import './ReviewPosts.scss';
import {useLocation, useParams} from "react-router-dom";
import api from "../../services/api";

function ReviewPosts() {

    const [isActivePostOriginal, setActivePostOriginal] = useState(false);
    const [isActiveView, setActiveView] = useState(true);
    const [isActiveReviewPosts, setActiveReviewPosts] = useState(true);
    const [isActiveReportComments, setActiveReportComments] = useState(false);
    const [isActiveModal, setActiveModal] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const [post, setPost] = useState({});
    const [modalTitle, setModalTitle] = useState();
    const [previusId, setPreviusId] = useState(0);

    const handlePost = (newData) => {
        setPost(prevPost => ({...prevPost, ...newData}));
    }
    const getPost = async () => {
        if (Object.keys(post).length === 0 || (previusId !== id && previusId !== 0)) {
            let data = {}
            await api.get(`/posts/${id}`, {
                withCredentials: true
            }).then(response => {
                data = response.data
                setPreviusId(data._id)
            })
                .catch(error => console.error('Erro na requisição:', error));
            handlePost(data)
        }
    }
    useEffect(() => {
        getPost()
    }, [id])
    const togglePostOriginal = () => {
        setActivePostOriginal(!isActivePostOriginal);
        setActiveView(!isActiveView);
    };
    const toggleView = () => {
        setActiveView(!isActiveView);
        setActivePostOriginal(!isActivePostOriginal);
    };
    const sendReview = () => {
        setActiveReviewPosts(false)
        setActiveReportComments(true)
    };
    const backReview = () => {
        setActiveReviewPosts(true)
        setActiveReportComments(false)
    };
    const handleApprovedAndOpenModal = async () => {
        openModal();
        setModalTitle("Matéria aprovada com sucesso!");
        let data = {}
        await api.put(`/posts/${id}`,{
            "review": 'approved',
        }, {
            withCredentials: true
        }).then(response => {
            data = response.data
        })
            .catch(error => console.error('Erro na requisição:', error));
        handlePost(data)
    }
    const handleRejectedAndOpenModal = async () => {
        openModal();
        setModalTitle("Matéria rejeitada!");
        let data = {}
        await api.put(`/posts/${id}`,{
            "review": 'rejected',
        }, {
            withCredentials: true
        }).then(response => {
            data = response.data
        })
            .catch(error => console.error('Erro na requisição:', error));
        handlePost(data)
    }
    const openModal = () => {
        setActiveModal(true);
    };
    const handleClose = () => {
        setActiveModal(false);
    };
    return (
        <>
            <Navbar />
            <Modals isActiveModal={isActiveModal} handleClose={handleClose} title={modalTitle} />
            <div className="Content ReviewPosts">
                {isActiveReviewPosts ?
                    <Row>
                        <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                            <Button className={`Tab TabPrimary ${isActivePostOriginal ? 'active' : null}`} onClick={togglePostOriginal}>Original</Button>
                            <Button className={`Tab TabPrimary ${isActiveView ? 'active' : null}`} onClick={toggleView}>Analise</Button>
                            {isActiveView ? <ViewPost onData={post} sendReview={sendReview} handleApprovedAndOpenModal={handleApprovedAndOpenModal} handleRejectedAndOpenModal={handleRejectedAndOpenModal} /> : null}
                            {isActivePostOriginal ? <PostOriginal onData={post} /> : null}
                        </Col>
                    </Row>
                    : null}
                {isActiveReportComments ?
                    <ReportComments onData={post} backReview={backReview} post={post} />
                    : null}
            </div>
        </>
    );
}
export default ReviewPosts;