import React, { useState } from 'react';
import './Filter.scss';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

function Filter({ onFilterChange }) {
  const [selectedType, setSelectedType] = useState({ name: 'Todos' });
  const [searchName, setSearchName] = useState('');

  const types = [
    { name: 'Todos' },
    { name: 'Ativo' },
    { name: 'Inativo' },
  ];

  const applyFilters = (filter, searchValue) => {
    let statusFilter = filter === 'Todos' ? '' : filter;
    let searchNameFilter = searchValue.trim() === '' ? '' : searchValue.trim();
    onFilterChange(statusFilter, searchNameFilter);
  };

  const handleFilterChange = (value) => {
    setSelectedType(value);
    applyFilters(value.name, searchName);
  };

  const handleSearchNameChange = (event) => {
    setSearchName(event.target.value);
    applyFilters(selectedType.name, event.target.value);
  };

  return (
    <div className='filter'>
      <span className="p-input-icon-right">
        <Icon icon='lucide:search' className='filter-icon' />
        <InputText
          placeholder="Pesquisar"
          className='filter-input'
          value={searchName}
          onChange={handleSearchNameChange}
        />
      </span>
      <Dropdown
        value={selectedType}
        onChange={(e) => handleFilterChange(e.value)}
        options={types}
        optionLabel='name'
        placeholder='Selecione filtro'
        className='filtro'
      />
    </div>
  );
}

export default Filter;
