import React, {useState} from 'react';
import { Col, Row } from "react-bootstrap";

import TotalArticles from '../../components/TotalArticles';
import ApprovedArticles from '../../components/ApprovedArticles';
import DisapprovedArticles from '../../components/DisapprovedArticles';
import FilterArticles from '../../components/FilterArticles';
import ArticleList from '../../components/ArticleList';

//components
import Navbar from '../../components/Navbar';

//styles
import './MyArticles.scss';

function MyArticles() {

    const [filteredArticles, setFilteredArticles] = useState(null);
    const [searchName, setSearchName] = useState('');

    const handleFilterChange = (filter, nameFilter) => {
        setFilteredArticles(filter);
        setSearchName(nameFilter);
    };
    return (
        <>
            <Navbar />
            <div className="Content page-myArticles">
                <div className='container-fluid'>
                    <div className='myArticles'>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className='mx-auto'>
                                <div className="Title titlePrimary">
                                    Minhas matérias
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={8} className='myArticlesCards'>
                                <Row>
                                    <Col xs={4} sm={3} md={3} lg={4}>
                                        <div className='cards'>
                                            <TotalArticles />
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={3} md={3} lg={4}>
                                        <div className='cards'>
                                            <ApprovedArticles />
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={3} md={3} lg={4}>
                                        <div className='cards'>
                                            <DisapprovedArticles />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <div className='filter'>
                                    <FilterArticles onFilterChange={handleFilterChange} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className='List'>
                                    <ArticleList filter={filteredArticles} nameFilter={searchName} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MyArticles;