import React, { useState } from 'react';
import './PasswordRecovery.scss';
import CodeConfirm from '../CodeConfirm';
import Login from '../../pages/Login';
import { Icon } from '@iconify/react';
import LogoLogin from '../LogoLogin';
import api from "../../services/api";
import Alerts from '../../components/Alerts';
import { useNavigate } from "react-router-dom";

function PasswordRecovery() {

  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [showCodeConfirm, setShowCodeConfirm] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isTitle, setTitle] = useState('');
  const [initDescription, setDescription] = useState('');
  const [initType, setType] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsVisible(false);

    const emailInput = document.getElementById('username');
    const iconMail = document.getElementById('mail');

    if (email.trim() === '') {
      emailInput.classList.add('usernameWrong');
      iconMail.classList.add('iconWrong');
      return;
    }
    if (email === 'teste') {
      setShowCodeConfirm(true);
    } else {
      emailInput.classList.add('usernameWrong');
      iconMail.classList.add('iconWrong');
      try {
        api.post(`/authentication/forgot-password`, {
          email: email
        }, {
          withCredentials: true
        }).then((res) => {
          setIsVisible(true);
          setType('success');
          setTitle('Recuperação iniciada');
          setDescription('Verifique seu email para cadastrar uma nova senha');
          emailInput.classList.remove('usernameWrong');
          iconMail.classList.remove('iconWrong');
          setTimeout(() => {
            navigate('/login')
          }, 4000);
        })
        .catch ((err)=> {
          setIsVisible(true);
          setType('danger');
          setTitle('E-mail não encontrato');
          setDescription('Não foi possível recuperar sua senha');
        })
      } catch (err) {
          setIsVisible(true);
          setType('danger');
          setTitle('Erro na conexão');
          setDescription('Não foi possível recuperar sua senha');
      }
    }
  };

  const handleBack = () => {
    setShowLogin(true);
  };

  if (showCodeConfirm) {
    return <CodeConfirm />;
  }

  if (showLogin) {
    return <Login />;
  }

  return (
    <div className="page-login">
      {isVisible &&
        <Alerts title={isTitle} description={initDescription} type={initType}/>
      }
      <div className="container-fluid">
        <div className='row'>
          <div className='col-12' id='logo'>
            <LogoLogin />
          </div>
          <div className='col-12' id='forms'>
            <div className='form'>
              <div className='title'>
                <h4> Redefinir senha </h4>
                <h6> Insira o seu e-mail e nós enviaremos instruções para criar uma nova senha </h6>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <span className='icon' id="mail">
                  <Icon id='mail' icon="line-md:email" />
                  </span>
                  <input
                    type="text"
                    id="username"
                    value={email}
                    placeholder="E-mail"
                    onChange={handleEmailChange}
                  />
                </div>
                <button className='Btns ButtonPrimary' id="recovery" type="submit">Recuperar Senha</button>
                <button className='Btns ButtonLight' id="back" onClick={handleBack}>Voltar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRecovery;
