import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import './Form.scss';
import TypeSelect from "../../TypeSelect";
import StatusSelect from "../../StatusSelect";
import Alerts from "../../../../components/Alerts";

import api from "../../../../services/api";
import { useAuth } from "../../../../context/useAuth";


function FormCreate() {

    const navigate = useNavigate()

    const { user, loading } = useAuth()
    const [roleSelector, setRoleSelector] = useState([])
    const [roleList, setRoleList] = useState([])

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');
    const [status, setStatus] = useState('inativo')

    const [account, setAccount] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [role, setRole] = useState()
    const [typeVal, setTypeVal] = useState()
    const [typeIconVal, setTypeIconVal] = useState()
    


    const handleAccount = (v_account) => {
        setAccount(v_account)
    }
    const handleName = (v_name) => {
        setName(v_name)
    }
    const handleEmail = (v_email) => {
        setEmail(v_email)
    }

    const handleRole = (v_role) => {
        setRole(v_role)
    }

    const handleValidationType = (v_typeval) => {
        setTypeVal(v_typeval)
    }

    const handleValidationIconType = (v_typeiconval) => {
        setTypeIconVal(v_typeiconval)
    }

    const handleRoleList = (roles) => {
        let list = []
        roles.forEach((d_role) => {

            if (d_role.name === "admin" && user.role === "admin") {
                list.push({
                    name: d_role.name
                })
            }
            if (d_role.name !== "admin") {
                list.push({
                    name: d_role.name
                })
            }
        })
        setRoleSelector(list)
        setRoleList(roles)
    }

    async function getRoles() {
        await api.get(`/roles`,
            { withCredentials: true }
        ).then(res => handleRoleList(res.data))

            .catch(err =>
                alert('Erro ao carregar os cargos!'))
    }

    useEffect(() => {
        if (user) {
            getRoles()
        }
    }, [user])

    useEffect(() => {
        if (user && roleList) {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);
            const id = params.get('id');
        }
    }, [loading, user, roleList])


    const handleAccountChange = (event) => {
        setAccount(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleRoleChange = (value) => {
        setRole(value);
    };

    

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const getRoleId = () => {
        let id = "";

        roleList.forEach((c_role) => {
            if (typeof role === "string" && role === c_role.name) {
                id = c_role._id;
            } else if (typeof role === "object" && role !== null && role.name === c_role.name) {
                id = c_role._id;
            }
        });

        return id;
    };

    const validateEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const handleSubmit = async () => {
        setIsVisible(false);

        const roleId = getRoleId()
        const sendRelationShip = {
            accountName: account,
            userName: name,
            userEmail: email,
            role: roleId,
            accountStatus: typeof status == 'string' ? status.toLowerCase() == "ativo" ? true : false : status.name.toLowerCase() == "ativo" ? true : false,
        }

        const InputAccount = document.getElementById('accountInput');
        const InputUser = document.getElementById('userInput');
        const InputMail = document.getElementById('mailInput');

        const iconAccount = document.getElementById('accountIcon');
        const iconUser = document.getElementById('userIcon');
        const iconMail = document.getElementById('mailIcon');
        const iconType = document.getElementById('typeIcon');
        //const iconStatus = document.getElementById('statusIcon');

        iconAccount.classList.remove('iconWrong');
        iconUser.classList.remove('iconWrong');
        iconMail.classList.remove('iconWrong');
        iconType.classList.remove('iconWrong');
        //iconStatus.classList.remove('iconWrong');


        InputAccount.classList.remove('inputWrong');
        InputUser.classList.remove('inputWrong');
        InputMail.classList.remove('inputWrong');
        //InputType.classList.remove('inputWrong');
        //InputStatus.classList.remove('inputWrong');

        if (InputAccount.value.trim() === '') {
            InputAccount.classList.add('inputWrong');
            iconAccount.classList.add('iconWrong');
            return;
        }

        if (InputUser.value.trim() === '') {
            InputUser.classList.add('inputWrong');
            iconUser.classList.add('iconWrong');
            return;
        }

        if (InputMail.value.trim() === '') {
            InputMail.classList.add('inputWrong');
            iconMail.classList.add('iconWrong');
            return;
        }

        if (!validateEmail(InputMail.value.trim())) {
            InputMail.classList.add('inputWrong');
            iconMail.classList.add('iconWrong');
            return;
        }

         if (role === '' || typeof role  === 'undefined') {
             handleValidationType("inputWrong")
             iconType.classList.add('iconWrong');
             return;
         }else{
            handleValidationType("")
            iconType.classList.remove('iconWrong');
         }

        // if (InputStatus.value.trim() === '') {
        //     //InputStatus.classList.add('inputWrong');
        //     iconStatus.classList.add('iconWrong');
        //     return;
        // }

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        try {

            await api.post(`/accounts/register-account-user`, sendRelationShip, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setType('success');
                setTitle('Sucesso');
                setDescription('Cadastro realizado!');

                setTimeout(() => {
                    navigate('/accounts');
                }, 2000);
            })
        } catch (err) {
            setIsVisible(true);
            setType('danger');
            setTitle('Erro');
            setDescription('Usuário já cadastrado!');

            if (!roleId) {
                setIsVisible(true);
                setType('danger');
                setTitle('Erro');
                setDescription('Necessário selecionar um tipo de usuário!');
            }
        }
    };

    return (

        <div className='form'>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <div className="information">
                <span> Preencha os campos abaixo para cadastrar seu novo publisher </span>
            </div>
            <form>
                <div className="form-group">

                    <span className='icon'>
                        <Icon id='accountIcon' icon="mdi:accounts-outline" />
                    </span>
                    <input type="text" className="form-control" id="accountInput" placeholder="Publisher" value={account}
                        onChange={handleAccountChange} />

                    <span className='icon'>
                        <Icon id='userIcon' icon="ph:user-bold" />
                    </span>
                    <input type="text" className="form-control" id="userInput" placeholder="Nome" value={name}
                        onChange={handleUsernameChange} />

                    <span className='icon'>
                        <Icon id='mailIcon' icon="line-md:email" />
                    </span>
                    <input type="text" className="form-control" id="mailInput" placeholder="E-mail" value={email}
                        onChange={handleEmailChange} />

                    <div className="select">
                        <span className='icon'>
                            <Icon id='typeIcon' icon="fluent-mdl2:world" />
                        </span>
                        <TypeSelect id="typeInput" validationType={typeVal}  roleSelector={roleSelector} selectedRole={role} handleRoleChange={handleRoleChange} />

                        <span className='icon'>
                            <Icon id='statusIcon' icon="fluent-mdl2:world" />
                        </span>
                        <StatusSelect id="statusInput" value={status} currentSelectedStatus={status} handleStatusChange={handleStatusChange} />
                    </div>
                    
                </div>

                <div className="button">
                    <Link to="/accounts" className="btn back">VOLTAR</Link>
                    <input type="button" onClick={handleSubmit} className="btn create" value="CADASTRAR"></input>
                </div>

            </form>
        </div>
    );
}
export default FormCreate;

