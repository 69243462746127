import React from 'react';
import './FilterArticles.scss';
import { Icon } from '@iconify/react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';


function FilterArticles({ onFilterChange }) {

    const [selectedFilter, setSelectedFilter] = useState({ name: 'Todos', code: 'all' });
    const [searchName, setSearchName] = useState(''); 

    const status = [
        { name: 'Todos', code: 'all' },
        { name: 'Aprovado', code: 'approved' },
        { name: 'Reprovado', code: 'rejected' },
        { name: 'Pendente', code: 'pending' },
        { name: 'Corrigir', code: 'fix' },
        { name: 'Aguardando', code: 'waiting' },
    ];

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        applyFilters(filter, searchName);
    };

    const handleSearchNameChange = (event) => {
        setSearchName(event.target.value);
        applyFilters(selectedFilter, event.target.value);
    };

    const applyFilters = (filter, searchValue) => {
        let statusFilter = filter.code === 'all' ? '' : filter.code;
        let searchNameFilter = searchValue.trim() === '' ? '' : searchValue.trim();
        onFilterChange(statusFilter, searchNameFilter);
    };

    return (
        <div className='filterArticles'>
            <span className="p-input-icon-right">
                <Icon icon='lucide:search' className='filter-icon' />
                <InputText
                    placeholder="Pesquisar"
                    className='filter-input'
                    value={searchName}
                    onChange={handleSearchNameChange}
                />
            </span>
            <div className='filterArticlesSelect'>
                <Dropdown
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.value)}
                    options={status}
                    optionLabel="name"
                    placeholder="Selecione filtro"
                    className='filtro'
                />
            </div>
        </div>
    );
}

export default FilterArticles;
