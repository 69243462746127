import React, { useEffect, useState } from "react";

import { VictoryChart, VictoryArea, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer } from "victory";

// Styles
import "./PostsVolume.scss"
import { useAuth } from "../../context/useAuth";
import api from "../../services/api";
import { isMobile } from 'react-device-detect';


export default function PostsVolume() {


    const [isWeek, setIsWeek] = useState(true)
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(320)

    function converterFormatoData(data) {
        const dataObj = new Date(data);
        const options = { month: 'short', day: 'numeric' };
        const dataFormatada = dataObj.toLocaleDateString('en-US', options);
        return dataFormatada;
      }

    const [graphData,setGraphData] = useState([
     
    ])

   

    const handleSize = (height, width) => {
        console.log("handleSize")
        setWidth(width)
        setHeight(height)
    }

    function checkWindowSize() {
        let currentWidth = window.innerWidth
      
        if (currentWidth >= 2000 && currentWidth <= 4000) {
           
            handleSize(320,1000)
        } else {
            if (currentWidth >= 1000 && currentWidth < 2000) {
                handleSize(320,800)
            } else {
                if (currentWidth < 1000) {
                    handleSize(380,500)
                }
            }
        }

    }

    useEffect(() => {

        window.addEventListener("load", checkWindowSize)
        window.addEventListener("resize", checkWindowSize)

    }, [])

    const toggleFilter = (value) => {
        setIsWeek(value)
        getGraphicData(isWeek)
    }

    const {user} = useAuth()

    async function getGraphicData(isWeek){
        await api.get(`/posts/graphic/${isWeek?'month':'week'}/account/${user.account}`,{
            withCredentials:true
        }).then((res)=>{
        
            let content = []
            Object.keys(res.data).forEach((key)=>{
                content.push({
                    x:converterFormatoData(key),
                    y:res.data[key]
                })
            })
            setGraphData(content)
        })
    }

    useEffect(()=>{
        if(user){
            getGraphicData()
        }
    },[user])

    return (<div className="volume-chart">
        <div className="box">
            <div className='HeaderTitle'>
                <span>Volume de matérias criadas</span>
                <div className="filter">
                    <ul>
                        <li onClick={() => { toggleFilter(true) }}>
                            <span>Semana</span>
                        </li>
                        <li onClick={() => { toggleFilter(false) }}>
                            <span>Mês</span>
                        </li>
                        <li className={isWeek ? `selector start` : `selector end`}></li>
                    </ul>
                </div>
            </div>
            <div className="graph">
                <VictoryChart
                    width={width}

                    height={height}
                    containerComponent={<VictoryVoronoiContainer />}
                >
                    <VictoryArea
                        interpolation="natural"
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }
                        }}
                        labelComponent={
                            <VictoryTooltip
                                flyoutStyle={{ fill: 'rgba(0, 0, 0, 0.8)' }} // Define o estilo do tooltip
                                style={{ fill: '#fff', fontSize: 12, zIndex: 999999 }} // Define o estilo do texto dentro do tooltip
                                renderInPortal={false}

                            />
                        }
                        labels={({ datum }) => `Valor: ${datum.y.toFixed(0)}`}
                        data={graphData}
                        domain={{ y: [0, 10] }}
                        style={{
                            data: {
                                fill: 'url(#gradient)',
                                stroke: isMobile ? '#2BB2E5' : 'none',
                                strokeWidth: isMobile ? '2px' : 'none'
                            }
                        }}
                    >
                    </VictoryArea>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            {isMobile ?
                                <>
                                    <stop offset="0%" stopColor="#838CD4" />
                                    <stop offset="100%" stopColor="#262B52" />
                                </> : 
                                <>
                                    <stop offset="0%" stopColor="#C2C2C2" />
                                    <stop offset="100%" stopColor="#FFFFFF" />
                                </>
                            }
                        </linearGradient>
                    </defs>
                    <VictoryAxis
                        style={{
                            grid: { stroke: '#e0e0e0', strokeDasharray: '2 2' }, // Cor da linha da grade
                            ticks: { size: 0 }, // Tamanho dos ticks (zero para ocultá-los)
                            tickLabels: { fill: '#B6B6B6', fontFamily: "var(--bs-body-font-family)" }, // Torna as labels dos ticks transparentes
                        }}

                    />
                    <VictoryAxis
                        dependentAxis
                        style={{ // Cor da linha da grade
                            ticks: { size: 0 }, // Tamanho dos ticks (zero para ocultá-los)
                            tickLabels: { fill: 'transparent' }, // Torna as labels dos ticks transparentes
                            axis: {
                                stroke: 'none'
                            }
                        }}

                    />

                </VictoryChart>

            </div>
        </div>
    </div>)
}
