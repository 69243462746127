import React, { useState, useEffect } from 'react';
import './LogoLogin.scss';
import logoImage from './img/Logo.svg';
import circule1 from './img/circule1.svg';
import circule2 from './img/circule2.svg';
import circule3 from './img/circule3.svg';
import circule4 from './img/circule4.svg';
import circule5 from './img/circule5.svg';
import circule6 from './img/circule6.svg';
import circule7 from './img/circule7.svg';

function LogoLogin() {
  const [circle1Position, setCircle1Position] = useState({ x: 0, y: 0 });
  const [circle2Position, setCircle2Position] = useState({ x: 0, y: 0 });
  const [circle3Position, setCircle3Position] = useState({ x: 0, y: 0 });
  const [circle4Position, setCircle4Position] = useState({ x: 0, y: 0 });
  const [circle5Position, setCircle5Position] = useState({ x: 0, y: 0 });
  const [circle6Position, setCircle6Position] = useState({ x: 0, y: 0 });
  const [circle7Position, setCircle7Position] = useState({ x: 0, y: 0 });

  const MIN_X = 0;
  const MAX_X = window.innerWidth;
  const MIN_Y = 0;
  const MAX_Y = window.innerHeight;

  const handleMouseMove = (event) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const x = Math.max(MIN_X, Math.min(MAX_X, mouseX));
    const y = Math.max(MIN_Y, Math.min(MAX_Y, mouseY));

    setCircle1Position({ x: x * -0.05, y: y * -0.05 });
    setCircle2Position({ x: x * 0.05, y: y * -0.05 });
    setCircle3Position({ x: x * 0.05, y: y * 0.15 });
    setCircle4Position({ x: x * 0.0, y: y * 0.5 });
    setCircle5Position({ x: x * -0.05, y: y * 0.05 });
    setCircle6Position({ x: x * 0.1, y: y * 0.1 });
    setCircle7Position({ x: x * 0.05, y: y * 0.2 });
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="logo">
      <div className="circle1" style={{ transform: `translate(${circle1Position.x}px, ${circle1Position.y}px)` }}>
        <img src={circule1} alt="circule1" />
      </div>
      <div className="circle2" style={{ transform: `translate(${circle2Position.x}px, ${circle2Position.y}px)` }}>
        <img src={circule2} alt="circule2" />
      </div>
      <div className="circle3" style={{ transform: `translate(${circle3Position.x}px, ${circle3Position.y}px)` }}>
        <img src={circule3} alt="circule3" />
      </div>
      <div className="circle4" style={{ transform: `translate(${circle4Position.x}px, ${circle4Position.y}px)` }}>
        <img src={circule4} alt="circule4" />
      </div>
      <div className="circle5" style={{ transform: `translate(${circle5Position.x}px, ${circle5Position.y}px)` }}>
        <img src={circule5} alt="circule5" />
      </div>
      <div className="circle6" style={{ transform: `translate(${circle6Position.x}px, ${circle6Position.y}px)` }}>
        <img src={circule6} alt="circule6" />
      </div>
      <div className="circle7" style={{ transform: `translate(${circle7Position.x}px, ${circle7Position.y}px)` }}>
        <img src={circule7} alt="circule7" />
      </div>
      <div className="name">
        <img src={logoImage} alt="logo" />
      </div>
    </div>
  );
}

export default LogoLogin;
