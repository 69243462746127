import React, {useState} from "react";
import { Col, Row } from "react-bootstrap";

//components
import Navbar from '../../components/Navbar';
import Filter from '../../components/MyPublishers/Filter';
import Button from '../../components/MyPublishers/Button';
import List from '../../components/MyPublishers/ListWriter';

//styles
import './Accounts.scss';

function Accounts() {
    const [filter, setFilter] = useState(null);
    const [searchName, setSearchName] = useState('');
  
    const handleFilterChange = (value, nameFilter) => {
      setFilter(value);
      setSearchName(nameFilter);
    };
  
    return (
      <>
        <Navbar />
        <div className='Content Accounts'>
          <Row  className='content'>
              <Col xs={12} sm={6} md={8} lg={6} className='mx-auto'>
                <div className='Title titlePrimary'>Meus Publishers</div>
              </Col>
              <Col xs={12} sm={6} md={8} lg={6} className='mx-auto'>
                <div className='actions'>
                  <Filter onFilterChange={handleFilterChange} />
                  <Button />
                </div>
              </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='List'>
                <List filter={filter} nameFilter={searchName} />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default Accounts;

