import React, {useState} from "react";
import { useEffect } from "react";

function Original({onData, title, text}) {
    const [postTitleOriginal, setPostTitleOriginal] = useState();
    const [postTextOriginal, setPostTextOriginal] = useState();
    const [postUrlOriginal, setPostUrlOriginal] = useState();
    useEffect(()=>{
        if (onData) {
            setPostTitleOriginal(onData?.original_title)
            setPostTextOriginal(onData?.original_text)
            setPostUrlOriginal(onData?.original_url)
        }
    },[onData])

    return (
        <>
            <div className="Original">
                <div className="EditorTitle">
                    <div className="titlePrimary">
                        <h1 className='title'>
                            {
                                postTitleOriginal
                            }
                        </h1>

                    </div>
                </div>
                <div className="postContent" dangerouslySetInnerHTML={{ __html: postTextOriginal }}></div>
                <br/>
                <span><strong>Fonte:</strong> {postUrlOriginal}</span>
            </div>
        </>
    );
}

export default Original;
