import React, {useState} from "react";
import { Icon } from '@iconify/react';
import { Button, Col, Row, Collapse } from "react-bootstrap";

function Feedback ({post}) {
    
    const [isActiveFeedback, setActiveFeedback] = useState(false)
    const collapseFeedback = () => {
        return setActiveFeedback(!isActiveFeedback)
    }

    return (
        <Row>
            <Col>
                <div className="Collapse">
                    <Button className={`Btns ButtonCollapse ${isActiveFeedback ? 'active' : ''}`} onClick={collapseFeedback}>
                        <i className="Icons Icon"><Icon icon={"ant-design:comment-outlined"}></Icon></i>
                        Feedback do Editor
                        <i className="Icons IconDowm"><Icon icon={"ep:arrow-down-bold"}></Icon></i>
                    </Button>
                    <Collapse in={isActiveFeedback}>
                        <div className="collapseFeedback">
                            <div>
                                {post.comments || 'Seu editor ainda não deixou um feedback.'}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </Col>
        </Row>
    );
}

export default Feedback;