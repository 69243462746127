import { React, useState, useEffect, Fragment, useRef } from 'react'
import './index.scss';
import CardPost from '../CardPost';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from "primereact/autocomplete";
import unidecode from 'unidecode';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { convertEpochToDate, dateToString } from '../../utils/dates';
import { useAuth } from '../../context/useAuth';
import api from "../../services/api";
import { Button, Col, Form, Row } from "react-bootstrap";

  const TrendingPosts = () => {
  const { user } = useAuth();

  const autoCompleteRefCategorys = useRef(null);
  const autoCompleteRefSites = useRef(null);
  const { isAdmin } = useAuth()
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [searchFieldActive, setSearchFieldActive] = useState(false);
  const [customSearchFieldActive, setCustomSearchFieldActive] = useState(false);
  const [categorys, setCategorys] = useState([]);
  const [selectedCategorys, setSelectedCategorys] = useState([]);
  const [sites, setSites] = useState([]);
  const [removeSites, setRemoveSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [filteredCategorys, setFilteredCategorys] = useState(null);
  const [filteredSites, setFilteredSites] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [buttonActive, setButtonActive] = useState('24');

  const Feed = async (categorias, sitesLiberados, sitesRemovidos) => {
    await api.get("/buzzsumo/trends",
      {
        params: {
          "hours": "24",
          "blocked_domains": sitesRemovidos,
          "topic": categorias,
          "sites": sitesLiberados
          
        }, withCredentials: true
      }).then(function (response) {
        setData(response.data.results);
        setLoadingData(true)
        setFilteredData(response.data.results)
        setCustomSearchFieldActive(false)
        return response
      })
  }

  useEffect(() => {
    const normalizedSearchText = unidecode(searchText).toLowerCase();

    const filtered = data.filter(item => {
      const normalizedTitle = unidecode(item.title).toLowerCase();

      return (normalizedTitle.includes(normalizedSearchText)
      )
    });

    setFilteredData(filtered);
    if (typeof user !== 'undefined' && accountId === 0) {
      setAccountId(user.account);
    }

    if (accountId !== 0 && firstTime) {
      setFirstTime(false);
      api.get(`settings/${accountId}`, { withCredentials: true })
        .then(response => {

          let categorias =  response.data.categories.join(",");
          let sitesLiberados =  response.data.relatedSites.join(",");
          let sitesRemovidos = response.data.removeSites.join(",");

          setCategorys(categorias)
          setSites(sitesLiberados)
          setRemoveSites(sitesRemovidos)

          Feed(categorias, sitesLiberados, sitesRemovidos)
        })
        .catch(error => {
          console.error("Erro ao obter dados de configuração:", error);
        });
    }

  }, [data, user, accountId])

  const customGetByCategory = async (categoryArr, sites, hours) => {

      const categorysStr = categoryArr.join(", ")
      const sitesStr = sites.join(", ")
      await api.get("/buzzsumo/trends",
        {
          params: {
            "hours": hours,
            "blocked_domains": removeSites,
            "topic": categorysStr,
            "sites": sitesStr
          }, withCredentials: true
        }).then(function (response) {
          setData(response.data.results);
          setLoadingData(true)
          setFilteredData(response.data.results)
          setCustomSearchFieldActive(false)
          return response
        })
    

    initClose();
  }

  const search = (event) => {
    setTimeout(() => {
      let _filteredCategorys;
      let _filteredSites;

      if (!event.query.trim().length) {
        _filteredCategorys = [...categorys];
        _filteredSites = [...sites];
      }
      else {
        _filteredCategorys = categorys.filter((category) => {
          return category.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      setFilteredCategorys(_filteredCategorys);

    }, 250);
  }

  function calculateTimePassed(dateStr) {
    var dateRegex = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;

    if (!dateRegex.test(dateStr)) {
      console.error("Invalid date format. Please use the format YYYY/MM/DD HH:MM:SS.");
      return
    }

    var providedDate = new Date(dateStr);

    if (isNaN(providedDate)) {
      console.error("The provided date is invalid or incorrect.");
      return
    }

    var currentDate = new Date();

    if (providedDate > currentDate) {
      console.error("The provided date is in the future.");
      return
    }

    var difference = currentDate - providedDate;
    var millisecondsInSecond = 1000;
    var secondsInMinute = 60;
    var minutesInHour = 60;
    var hoursInDay = 24;
    var millisecondsInMinute = millisecondsInSecond * secondsInMinute;
    var millisecondsInHour = millisecondsInMinute * minutesInHour;
    var millisecondsInDay = millisecondsInHour * hoursInDay;
    var millisecondsInWeek = millisecondsInDay * 7;
    var millisecondsInMonth = millisecondsInDay * 30;
    var millisecondsInYear = millisecondsInDay * 365;

    if (difference >= millisecondsInYear) {
      var years = Math.floor(difference / millisecondsInYear);
      return years + (years === 1 ? " ano" : " anos");
    } else if (difference >= millisecondsInMonth) {
      var months = Math.floor(difference / millisecondsInMonth);
      return months + (months === 1 ? " mês" : " meses");
    } else if (difference >= millisecondsInWeek) {
      var weeks = Math.floor(difference / millisecondsInWeek);
      return weeks + (weeks === 1 ? " semana" : " semanas");
    } else if (difference >= millisecondsInDay) {
      var days = Math.floor(difference / millisecondsInDay);
      return days + (days === 1 ? " dia" : " dias");
    } else if (difference >= millisecondsInHour) {
      var hours = Math.floor(difference / millisecondsInHour);
      return hours + (hours === 1 ? " hora" : " horas");
    } else if (difference >= millisecondsInMinute) {
      var minutes = Math.floor(difference / millisecondsInMinute);
      return minutes + (minutes === 1 ? " minuto" : " minutos");
    } else {
      var seconds = Math.floor(difference / millisecondsInSecond);
      return seconds + (seconds === 1 ? " segundo" : " segundos");
    }
  }

  const itemTemplate = ({ title, category, description, thumbnail, trending, published_date, url, total_shares }) => {

    const date = dateToString(convertEpochToDate(published_date))

    return (
      <CardPost title={title} category={category} description={description} img={thumbnail} trending={trending} url={url} total_shares={total_shares} date={calculateTimePassed(date)} />
    )
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 6, value: 6 },
        { label: 12, value: 12 },
        { label: 18, value: 18 },
        { label: 24, value: 24 },
        { label: 30, value: 30 }
      ];

      return (
        <Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Matérias por página:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    }
  };



  const handleClick = () => {
    setFilteredCategorys(categorys);
    autoCompleteRefCategorys.current.show()
  }

  const handleClickSite = () => {
    setFilteredSites(sites);
    autoCompleteRefSites.current.show()
  }


  const cardCategoryTemplate = (category) => {
    return (
      <div className='card-category'>
        <span>{category}</span>
        <Icon icon="system-uicons:close" onClick={(e => setSelectedCategorys(selectedCategorys.filter((category) => category !== e.target.parentElement.querySelector('span').innerHTML)))} />
      </div>
    )
  }

  const cardSiteTemplate = (site) => {
    return (
      <div className='card-category'>
        <span>{site}</span>
        <Icon icon="system-uicons:close" onClick={(e => setSelectedSites(selectedSites.filter((site) => site !== e.target.parentElement.querySelector('span').innerHTML)))} />
      </div>
    )
  }

  const overlayItemTemplate = (category) => {
    const isAdd = selectedCategorys.filter(e => e === category)

    return (
      <>
        <div id="item-category">

          <div>{category}</div>
          {isAdd.length !== 0 ? <div className='add' style={{ background: "#0092C9" }}>+ Add</div> : <div className='add' style={{ background: "#DADADA" }}>+ Add</div>}

        </div>
        <hr />
      </>

    );
  };

  const overlaySiteTemplate = (site) => {
    const isAdd = selectedSites.filter(e => e === site)

    return (
      <>
        <div id="item-category">

          <div>{site}</div>
          {isAdd.length !== 0 ? <div className='add' style={{ background: "#0092C9" }}>+ Add</div> : <div className='add' style={{ background: "#DADADA" }}>+ Add</div>}

        </div>
        <hr />
      </>

    );
  };

  const handleTime = (button) => {
    if (buttonActive !== button) {
      setButtonActive(button);
    }
  };

  const initClose = () => {
    setCustomSearchFieldActive(false);
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchFeed = async () => {
    await api.get("/buzzsumo/trends",
      {
        params: {
          "hours": buttonActive,
          "blocked_domains": removeSites,
          "topic": searchText
        }, withCredentials: true
      }).then(function (response) {
        setData(response.data.results);
        setLoadingData(true)
        setFilteredData(response.data.results)
        setCustomSearchFieldActive(false)
        return response
      })
  }


  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearchFeed()
    }
  }

  return (
    <div className={`trending-posts ${isAdmin() ? "admin" : ''}`}>
      <div className="search-container">
        <InputText style={{ width: "328px", height: "39px", marginRight: "8px" }} value={searchText} onKeyPress={handleKeyPress}  onChange={searchTextChange} placeholder="O que deseja buscar?" />
          
        <button className='Btns ButtonPrimaryLight' onClick={handleSearchFeed}>Pesquisar</button>


          <div className='custom-search-container'>
         
          <button className='Btns ButtonSecondary' onClick={() => { setCustomSearchFieldActive(!customSearchFieldActive) }}>Personalizar Resultado</button>

          {(selectedCategorys.length !== 0 || selectedSites.length !== 0) && <div className='counter-category'>{selectedCategorys.length + selectedSites.length}</div>}
        </div>
        <div className={`container-custom-search ${customSearchFieldActive ? 'active' : 'hide'}`}>
          {
            <>
              <div className="DropFilter h-100">
                <Row className="justify-content-center align-items-center h-100">
                  <Col xs={6} sm={6} md={6} lg={12}>
                    <Row className="">
                      <Col xs={6} sm={6} md={6} lg={12}>
                        <h4 className='line'>Personalizar Resultados <i onClick={initClose}><Icon icon="ep:close-bold" /></i></h4>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={12}>
                        <Form.Label className='Label'>Escolha as categorias desejadas</Form.Label>
                        <Form.Group className="grup line">
                          <AutoComplete itemTemplate={overlayItemTemplate} className="input-category" ref={autoCompleteRefCategorys} onClick={handleClick} multiple style={{ height: "50px", width: "100%" }} placeholder="Pesquisar" value={selectedCategorys} suggestions={filteredCategorys} completeMethod={search} onChange={(e) => setSelectedCategorys(e.value)} />
                          {selectedCategorys.length > 0 ? <span className='category-add'>Categorias adicionadas</span> : <></>}
                          <div className='card-category-container'>{selectedCategorys.map((category) => cardCategoryTemplate(category))}</div>
                        </Form.Group>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={12}>
                        <Form.Label className='Label'>Escolha os sites desejados</Form.Label>
                        <Form.Group className="grup line">
                          <AutoComplete itemTemplate={overlaySiteTemplate} className="input-category" ref={autoCompleteRefSites} onClick={handleClickSite} multiple style={{ height: "50px", width: "100%" }} placeholder="Pesquisar" value={selectedSites} suggestions={filteredSites} completeMethod={search} onChange={(e) => setSelectedSites(e.value)} />
                          {selectedSites.length > 0 ? <span className='category-add'>Sites adicionados</span> : <></>}
                          <div className='card-category-container '>{selectedSites.map((site) => cardSiteTemplate(site))}</div>
                        </Form.Group>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={12}>
                        <Form.Label className='Label'>Exibir por período</Form.Label>
                        <Form.Group className="grup paragrafos">
                          <Button
                            className={`Btns ButtonLight ${buttonActive === '1' ? 'active' : ''}`}
                            onClick={() => handleTime('1')}
                          >
                            Última 1 hora
                          </Button>
                          <Button
                            className={`Btns ButtonLight ${buttonActive === '2' ? 'active' : ''}`}
                            onClick={() => handleTime('2')}
                          >
                            Últimas 2 horas
                          </Button>
                          <Button
                            className={`Btns ButtonLight ${buttonActive === '24' ? 'active' : ''}`}
                            onClick={() => handleTime('24')}
                          >
                            Últimas 24 horas
                          </Button>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} className="text-center actions">
                        <Button className="Btns ButtonSecondary" id='apply-options' onClick={() => { customGetByCategory(selectedCategorys, selectedSites, buttonActive) }}>Aplicar preferências</Button>
                        <Button className="Btns ButtonBack" onClick={initClose}>Voltar</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
            // <div className='modal-custom-search'>
            //   <label className='header'>Escolha as categorias desejadas</label>
            //   <AutoComplete itemTemplate={overlayItemTemplate} className="input-category" ref={autoCompleteRef} onClick={handleClick} multiple style={{ height: "50px", width: "100%" }} placeholder="Pesquisar" value={selectedCategorys} suggestions={filteredCategorys} completeMethod={search} onChange={(e) => setSelectedCategorys(e.value)} />
            //   <span className='category-add'>Categorias adicionadas</span>
            //   <div className='card-category-container'>{selectedCategorys.map((category) => cardCategoryTemplate(category))}</div>
            //   <button className='Btns ButtonSecondary apply' onClick={() => { customGetByCategory(selectedCategorys) }}>Buscar</button>

            // </div>
          }
        </div>


      </div>
      
      <div className='container-trending-posts'>
        <div className='HeaderTitle'>
          <span>Top 100 matérias mais relevantes</span>
        </div>
        {!loadingData &&
          <div className='BodyTitle'>
            <p>Utilize os filtros para aprimorar e visualizar o feed de notícias.</p>
          </div>
        }
        {loadingData &&
          <DataView value={filteredData} itemTemplate={itemTemplate} paginatorClassName={"paginator"} paginatorTemplate={paginatorTemplate} sortField={"date"} sortOrder={-1} paginator rows={8} />
        }
      </div>
    </div>

  )
}

export default TrendingPosts