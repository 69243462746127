import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";

import './Form.scss';
import TypeSelect from "../../TypeSelect";
import StatusSelect from "../../StatusSelect";
import Alerts from "../../../../components/Alerts";
import api from "../../../../services/api";
import { useAuth } from "../../../../context/useAuth";

function FormEdit() {

    const navigate = useNavigate()

    const { user, loading } = useAuth()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const [accountName, setAccount] = useState()
    const [status, setStatus] = useState()
    const [isWriter, setIsWriter] = useState(false);


    const handleAccount = (v_account) => {
        setAccount(v_account)
    }

    const handleStatus = (v_status) => {
        setStatus(v_status)
    }


    async function getUserData(account, id) {
        await api.get(`/accounts/${id}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                const selectedUser = res.data
                handleAccount(selectedUser.name)
                handleStatus(selectedUser.active ? 'Ativo' : 'Inativo')

            } catch (err) {
                throw err
            }

        })
    }

    useEffect(() => {
        if (user) {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);
            const id = params.get('id');
            getUserData(user.account, id)
        }
    }, [loading, user])


    const handleAccountChange = (event) => {
        setAccount(event.target.value);
    };


    const handleStatusChange = (value) => {
        setStatus(value);
    };


    const handleSubmit = async (event) => {


        const sendRelationShip = {
            name: accountName,
            active: typeof status == 'string' ? status.toLowerCase() == "ativo" ? true : false : status.name.toLowerCase() == "ativo" ? true : false
        }

        const InputAccount = document.getElementById('accountInput');
        const iconAccount = document.getElementById('accountIcon');

        iconAccount.classList.remove('iconWrong');
        InputAccount.classList.remove('inputWrong');

        if (InputAccount.value.trim() === '') {
            InputAccount.classList.add('inputWrong');
            iconAccount.classList.add('iconWrong');
            return;
        }

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const accountsId = params.get('id');

        await api.put(`/accounts/${accountsId}`, sendRelationShip, {
            withCredentials: true
        }).then((res) => {
            console.log(sendRelationShip)
            setIsVisible(true);
            setType('success');
            setTitle('Sucesso');
            setDescription('Edição realizada!');

            setTimeout(() => {
                navigate('/accounts');
            }, 4000);
        })

    };

    return (

        <div className='form'>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <div className="information">
                <span>  Revise os dados abaixo para alterar a conta </span>
            </div>
            <form>
                <div className="form-group">

                <span className='icon'>
                        <Icon id='accountIcon' icon="ph:user-bold" />
                    </span>
                    <input type="text" className="form-control" id="accountInput" placeholder="Account" value={accountName}
                        onChange={handleAccountChange} />

                    <div className="select">

                        <span className='icon'>
                            <Icon id='statusIcon' icon="fluent-mdl2:world" />
                        </span>
                        <StatusSelect value={status} currentSelectedStatus={status} handleStatusChange={handleStatusChange} />
                    </div>

                </div>

                <div className="button">
                    <Link to="/accounts" className="btn back">VOLTAR</Link>
                    <input type="button" onClick={handleSubmit} className="btn create" value="CADASTRAR"></input>
                </div>

            </form>
        </div>
    );
}
export default FormEdit;
