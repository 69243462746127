import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { useAuth } from "../../../context/useAuth";
import { useNavigate } from "react-router-dom";
import Alerts from "../../Alerts";

function ReportComments({ onData, backReview }) {

    const [postTitleEditor, setPostTitleEditor] = useState();
    const [comments, setComments] = useState();

    const navigate = useNavigate()

    const [post, setPost] = useState()
    const { user, loading, token } = useAuth()
    const [redator, setRedator] = useState('');

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    useEffect(() => {
        if (onData) {
            setPostTitleEditor(onData?.title_editor)
            setRedator(onData?.id_user ? onData.id_user.name : '');

        }
    }, [onData])

    const handleCommentsChange = (event) => {
        setComments(event.target.value)
    }
    const handleComments = async ({nextPost = false}) => {
        let data = {}
        await api.put(`/posts/${onData._id}`, {
            "comments": comments,
            "review": "fix"
        }, {
            withCredentials: true
        }).then(response => {
            data = response.data
            if (nextPost) {
                handleReviewNextPost();
            }
            else {
                setIsVisible(true);
                setType('success');
                setTitle('Sucesso');
                setDescription('Matéria revisada com sucesso!');
                setTimeout(() => {
                    navigate('/my-articles')
                }, 2000);
            }
        }).catch(error => console.error('Erro na requisição:', error));
    }

    const handleReviewNextPost = async () => {
        setIsVisible(false);
        await api.get(`posts/next/pending/account/${user.account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                setPost(res.data)
                navigate(`/reviewposts/${res.data._id}`)
                backReview();
            } catch (err) {
                setIsVisible(true);
                setType('success');
                setTitle('Sucesso');
                setDescription('Todas matérias já foram revisadas!');
                setTimeout(() => {
                    navigate('/my-articles')
                }, 2000);
            }
        })
    }

    const handleButtonClick = () => {
        handleComments({nextPost: true});
    };
    

    return (
        <>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <Row>
                <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                    <div className='HeaderTitle'>
                        <span>Matérias a serem aprovadas</span>
                    </div>
                </Col>
                <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                    <div className="Comments">
                        <Row>
                            <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                <div className="Review">
                                    <Row>
                                        <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                            <div className="ReviewTitle">
                                                <Row>
                                                    <Col xs={12} sm={10} md={8} lg={7} className='mx-auto'>
                                                        <h2>{postTitleEditor}</h2>
                                                        <ul>
                                                            <li>Redator: <strong>{redator}</strong></li>
                                                        </ul>
                                                    </Col>
                                                    <Col xs={12} sm={10} md={8} lg={5} className='ReviewActions'>
                                                        <Button className="Btns ButtonLight" onClick={backReview}>Voltar</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={10} md={8} lg={12} className='mx-auto'>
                                            <div className="ReportComments">
                                                <h5>Deixe no campo abaixo seus apontamentos para revisão:</h5>
                                                <textarea className="form-control" rows="5" onChange={handleCommentsChange}></textarea>
                                                <div className="Actions">
                                                    <Button className="Btns ButtonLight" onClick={handleButtonClick}>Enviar e revisar a proxima matéria</Button>
                                                    <Button className="Btns ButtonPublic" onClick={handleComments}>Enviar</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ReportComments;
