import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// CSS
import "./App.scss"

// pages
import Home from './pages/Home';
import Login from './pages/Login';
import Posts from './pages/Posts';
import Users from './pages/Users';
import EditPost from './pages/Posts/Edit';
import MyArticles from './pages/MyArticles';
import NewPost from './pages/NewPost';
import ResultPost from './pages/NewPost/Edit';
import Dashboard from './pages/Dashboard';
import ManageReports from './pages/ManageReports';
import NewWriter from './components/MyWriter/NewWriter';
import EditWriter from './components/MyWriter/EditWriter';
import Config from './pages/Config';
import ReviewPosts from './pages/ReviewPosts';
import { CookiesProvider } from 'react-cookie';
import LoginRecovery from './pages/Login/LoginRecovery';
import Accounts from './pages/Accounts';
import NewAccount from './components/MyPublishers/NewAccount';
import EditAccount from './components/MyPublishers/EditAccount';
import AccountsList from './components/MyPublishers/AccountsList';
import Integrations from './pages/Integrations';

import Hotjar from '@hotjar/browser';


//styles
import './App.scss';
import { AuthContext, AuthProvider, useAuth } from './context/useAuth';
import PrivateRoute from './components/PrivateRoute';

function App() {

    const siteId = 3561461;
    const hotjarVersion = 6;
    
    Hotjar.init(siteId, hotjarVersion, {
        debug: true
    });

    return (
        <CookiesProvider>
            <Router>
                <AuthProvider>
                    <Routes>
                        {/* START ROTAS NÃO AUTENTICADAS */}
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/newPassword/:token" element={<LoginRecovery />} />
                        
                        {/* START ROTAS NÃO AUTENTICADAS */}

                        {/* START ROTAS AUTENTICADAS */}
                        <Route exact path='/' element={<PrivateRoute />}>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/posts" element={<Posts />} />
                            <Route exact path="/users" element={<Users />} />
                            <Route exact path="/newpost" element={<NewPost />} />
                            <Route exact path="/resultpost/:id" element={<ResultPost />} />
                            <Route exact path="/edit-post/:id" element={<EditPost />} />
                            <Route exact path="/my-articles" element={<MyArticles />} />
                            <Route exact path="/managereports" element={<ManageReports />} />
                            <Route exact path="/editwriter" element={<EditWriter />}/>
                            <Route exact path="/newwriter" element={<NewWriter />}/>
                            <Route exact path="/config" element={<Config />} />
                            <Route exact path="/reviewposts/:id" element={<ReviewPosts />} />
                            <Route exact path="/accounts" element={<Accounts />} />
                            <Route exact path="/editaccount" element={<EditAccount />}/>
                            <Route exact path="/newaccount" element={<NewAccount />}/>
                            <Route exact path="/listaccount" element={<AccountsList />}/>
                            <Route exact path="/integrations" element={<Integrations />}/>
                            
                        </Route>
                        {/* END ROTAS AUTENTICADAS */}

                        {/* START NOT FOUND*/}
                        <Route path="/*" element={<h1>404</h1>} />
                        {/* END NOT FOUND*/}
                    </Routes>
                </AuthProvider>
            </Router>
        </CookiesProvider>
    );
}

export default App;
