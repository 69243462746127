import React, { useEffect } from "react";

function Words({ onData }) {

    useEffect(() => {}, [onData?.keywords_chatgpt]);

    return (
        <>
            <div className="Words">
                <label>Palavras chave mais revelantes</label>
                <div className="list">
                    {
                        onData && onData.keywords_chatgpt
                            ? onData.keywords_chatgpt.map((word, index) => <span key={index}>{word}</span>)
                            : null
                    }
                </div>
            </div>
        </>
    );
}

export default Words;
