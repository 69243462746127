import "./FontSize.scss";

function FontSize({ onIncreaseFontSize, onDecreaseFontSize }) {

  return (
    <div className="fontSize Component">
      <div className="fontSizeTitle">
        <span></span><p>Tamanho da fonte:</p>
      </div>
      <div className="fontSizeValue">
        <button onClick={onDecreaseFontSize}>A-</button>
        <button onClick={onIncreaseFontSize}>A+</button>
      </div>
    </div>
  );
}

export default FontSize;

