import React from "react";
import Navbar from "../../Navbar";
import { Col, Row } from "react-bootstrap";

import { FormEdit } from "../Form";
import Image from "../Image";

import './EditAccount.scss';


function EditAccount() {

    return (
        <>
            <Navbar />
            <div className="Content EditAccount">
                <Row>
                    <div className="content">
                        <Col xs={6} sm={6} md={8} lg={12} className='mx-auto custom'>
                            <div className="Title titlePrimary">
                                Editar Publishers
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col xs={3} sm={3} md={3} lg={6} className='mx-auto'>
                        <Image />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={6} className="mx-auto allign-content">
                        <FormEdit/>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default EditAccount;