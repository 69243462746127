import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import { Icon } from '@iconify/react';
import './Rewriter.scss';

function RewritePost({initloadding, initOpen, onDataUpdate, urlPost, textPostTextarea, sumSizes}) {
    const [url, setUrl] = useState("");
    const [text, setTextTextarea] = useState("");

    const [isActiveDropFilter, setActiveDropFilter] = useState(false);

    const [isTextarea, setTextarea] = useState(false);
    

    const toggleCreatePost = () => {
        setActiveDropFilter(!isActiveDropFilter);
    };

    const toggleTextarea = () => {
        setTextarea(!isTextarea);
    };

    useEffect(() => {
        if (url === '') {
            setUrl(urlPost);
        }

        if (text === '') {
            setTextTextarea(textPostTextarea);
        }

        if(onDataUpdate) {
            console.log(text);
            onDataUpdate({
                url, text
            });
        }
    }, [url, urlPost, text, textPostTextarea]);

    return (
        <>
            <Form className="RewritePost">
                {!isTextarea ?
                <Form.Group className="GroupIcon  mb-3">
                    <Icon id="lock" icon="ph:link-bold" />
                    <Icon onClick={toggleTextarea} id="add-text-line" icon="clarity:add-text-line" />
                    <Form.Control
                        className='Inputs'
                        type="url"
                        placeholder="Cole aqui a URL desejada" 
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                    />
                </Form.Group>
                : null}
               
               {!isTextarea ?  <div className='custom-search-container'>
                    <Button className="Btns ButtonSecondary" onClick={initOpen}><i><Icon className="icons" icon="icon-park-outline:config" /></i> Configurações avançadas</Button>
                    {(sumSizes !== 0) && <div className='counter-category'>{sumSizes}</div>}
                </div> : null}
                {!isTextarea ? <Button className="Btns ButtonPrimary" onClick={initloadding}>Escrever</Button> : null}


                {isTextarea ?
                    <div className='pastedTextarea'>
                        <textarea className="pastedText form-control" type="text" placeholder="Cole o texto desejada" value={text} onChange={(e) => {
                                setTextTextarea(e.target.value);
                         }}></textarea>
                        <Icon onClick={toggleTextarea} id="link-bold" icon="ph:link-bold" />
                        <div className='custom-search-container'>
                            <Button className="Btns ButtonSecondary" onClick={initOpen}><i><Icon className="icons" icon="icon-park-outline:config" /></i> Configurações avançadas</Button>
                            {(sumSizes !== 0) && <div className='counter-category'>{sumSizes}</div>}
                        </div>
                        <Button className="Btns ButtonPrimary" onClick={initloadding}>Escrever</Button>
                    </div>
                : null}

            </Form>
        </>
    );
}

export default RewritePost;
