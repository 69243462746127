import React from 'react';

import '../Login.scss';

import NewPassword from '../../../components/NewPassword';

function LoginRecovery() {
  return (
    <>
        <NewPassword />
    </>
  );
}
export default LoginRecovery;