import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import './ArticleList.scss';
import { useAuth } from "../../context/useAuth";
import api from "../../services/api";
import { limitarString } from '../../utils/formatFunctions';
import { dateToLocaleStringCustom } from '../../utils/dates';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alerts from "../Alerts";

import Iconi7 from "./img/icon-i7.svg";
import Iconi7disable from "./img/icon-i7-disable.svg";


import 'react-tooltip/dist/react-tooltip.css'

function Tabela({ filter, nameFilter }) {

    const navigate = useNavigate()

    const { user, loading, token, isAdmin } = useAuth()
    const [postsForUser, setPostsForUser] = useState([]);
    const [goalPercent, setGoalPercent] = useState()
    const [intWp, setWp] = useState(false)
    const [intI7, setI7] = useState(false)
    const [isWpPost, setWpPost] = useState()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    async function getAllPostsForUser() {
        await api.get(`/posts/list/all`, { withCredentials: true }).then((res) => {
          try {
            let list = [];
            res.data.forEach((postContent) => {
              const createdAtDate = new Date(postContent.createdAt);
              
              list.push({
                createdAt: createdAtDate,
                id: postContent.id,
                redator: postContent.redator,
                status: postContent.status,
                is_wordpress: postContent.is_sended_wordpress,
                is_i7: postContent.is_sended_i7,
                title_editor: limitarString(postContent.title_editor || 'O editor ainda não escolheu o titulo', 80),
                difference_between_text: postContent.difference_between_text ===  undefined || postContent.difference_between_text ===  null ? '0%' : postContent.difference_between_text + '%'
              });
            });

            

            // Ordenar os posts pelo campo 'createdAt' em ordem decrescente
            list.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      
            setPostsForUser(list);
          } catch (err) {
            throw err;
          }
        });
    }
       

    async function getWpData(account) {
        await api.get(`/wordpress/${account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    if (!res.data[0]) {
                        console.log(res.data[0])
                        setWp(false)
                    }else{
                        setWp(true)
                    }
                }
                else{
                }
            } catch (err) {
                throw err
            }
        })
    }

    async function getI7Data(account) {
        await api.get(`/i7/${account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    if (!res.data[0]) {
                        console.log(res.data[0])
                        setI7(false)
                    }else{
                        setI7(true)
                    }
                }
                else{
                }
            } catch (err) {
                throw err
            }
        })
    }
      
    useEffect(() => {
        if (user) {
            getAllPostsForUser();
            getAccountGoal();
            getWpData(user.account);
            getI7Data(user.account);
        }
    }, [loading])

    async function handleWp(idPost){
        
        await api.post(`/wordpress/${idPost}`, {} ,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    getAllPostsForUser();
                    console.log(res);
                    setIsVisible(true);
                    setType('success');
                    setTitle('Parabéns!');
                    setDescription('Sua matéria foi enviada para o WordPress!');
                    setTimeout(() => {
                        setIsVisible(false);
                    }, 4000);
                }
                else{
                    console.log(res);
                    
                }
            } catch (err) {
                console.log(res)
                throw err
            }
        })
    }

    async function handleI7(idPost){
        
        await api.post(`/i7/${idPost}`, {} ,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    getAllPostsForUser();
                    console.log(res);
                    setIsVisible(true);
                    setType('success');
                    setTitle('Parabéns!');
                    setDescription('Sua matéria foi enviada para o WordPress!');
                    setTimeout(() => {
                        setIsVisible(false);
                    }, 4000);
                }
                else{
                    console.log(res);
                    
                }
            } catch (err) {
                console.log(res)
                throw err
            }
        })
    }

    function handleStatusText(rowData) {
        let statusText = "Corrigir";
        if (rowData.status === 'approved' || rowData.status === 'rejected' || rowData.status === 'pending') {
            statusText = "Visualizar";
        } else if (rowData.status === 'waiting') {
            statusText = "Continuar";
        }
        return statusText
    }

    function handleStatus(rowData) {
        let endpoint = `/resultpost/${rowData.id}`;
        if (rowData.status === 'pending') {
            endpoint = `/reviewposts/${rowData.id}`;
        }
        return endpoint
    }

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 6, value: 6 },
                { label: 12, value: 12 },
                { label: 18, value: 18 },
                { label: 24, value: 24 },
                { label: 30, value: 30 }
            ];

            return (
                <Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Matérias por página:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };

    const filteredPosts = useMemo(() => {
        
        if (filter && nameFilter) {
            return postsForUser.filter(
                (post) =>
                    post.status === filter &&
                    post.title_editor.toLowerCase().includes(nameFilter.toLowerCase())
            );
        } else if (filter) {
            return postsForUser.filter((post) => post.status === filter);
        } else if (nameFilter) {
            return postsForUser.filter((post) =>
                post.title_editor.toLowerCase().includes(nameFilter.toLowerCase())
            );
        } else {
            return postsForUser;

        }
       
    }, [postsForUser, filter, nameFilter]);

    async function getAccountGoal() {
        try {
            await api.get(`/settings/${user?.account}`, {
                withCredentials: true
            }).then((res) => {
                setGoalPercent(res.data.meta_for_posts)
            })
        } catch (err) {
            console.log(err)
        }
    }
    
    function dateToLocaleStringCustom(date) {
        const options = {
          day: 'numeric',
          month: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };
        return date.toLocaleString('pt-BR', options);
      }

    return (
        <>
        {isVisible ?
            <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
        }
        <div className="articleList">
            <div className="card">
                <DataTable
                    value={filteredPosts}
                    paginatorClassName={"paginator"} 
                    paginatorTemplate={paginatorTemplate} 
                    sortField="createdAt" 
                    sortOrder={-1} 
                    paginator 
                    rows={12}>
                        
                    <Column field="title_editor"
                        header="Título"
                        style={{ width: '32%' }}
                        bodyClassName="title-column">
                    </Column>

                    <Column field="redator"
                        header="Redator"
                        bodyClassName="title-column"
                        style={{ width: '12%' }} >
                    </Column>

                    <Column field="createdAt"
                        header="Data"
                        bodyClassName="title-column"
                        body={(rowData) => <span>{dateToLocaleStringCustom(rowData.createdAt)}</span>}
                        style={{ width: '10%' }} >
                    </Column>

                    <Column field="difference_between_text"
                        header="Alteração %"
                        bodyClassName={(rowData) =>
                            parseFloat(rowData.difference_between_text) < parseFloat(goalPercent) || parseFloat(rowData.difference_between_text) == 0
                                ? 'red-text'
                                : 'green-text'
                        }
                        style={{ width: '12%' }}
                    ></Column>


                    <Column
                        field="status"
                        header="status"
                        style={{ width: '12%' }}
                        body={(rowData) => {
                            let statusClass = '';
                            let statusName = '';
                            if (rowData.status === 'fix') {
                                statusClass = 'status-corrigir';
                                statusName = 'Corrigir';
                            } else if (rowData.status === 'approved') {
                                statusClass = 'status-aprovada';
                                statusName = 'Aprovada';
                            } else if (rowData.status === 'rejected') {
                                statusClass = 'status-reprovada';
                                statusName = 'Reprovada';
                            } else if (rowData.status === 'pending') {
                                statusClass = 'status-pendente';
                                statusName = 'Pendente';
                            } else if (rowData.status === 'waiting') {
                                statusClass = 'status-aguardando';
                                statusName = 'Aguardando';
                            }
                            return (
                                <span className={statusClass}>
                                    <span className="bollot"></span>&nbsp;
                                    <span className="status">{statusName}</span>
                                </span>
                            );
                        }}
                    ></Column>

                    <Column
                        field="Actions"
                        header=""
                        style={{ width: '22%' }}
                        body={(rowData) => {
                            let buttonClass = '';

                            if (rowData.status === 'fix') {
                                buttonClass = 'fix-button';
                            } else if (rowData.status === 'approved' || rowData.status === 'rejected' || rowData.status === 'pending') {
                                buttonClass = 'preview-button';
                            }
                            else if (rowData.status === 'waiting') {
                                buttonClass = 'waiting-button';
                            }
                            return (
                                <>
                                <a href={handleStatus(rowData)}><button className={buttonClass}>{handleStatusText(rowData)}</button></a>
                                {intWp ? 
                                    <>
                                        {(isAdmin() || user?.role === "jornalista") && rowData.is_wordpress == false || rowData.is_wordpress == "" ?
                                            <button data-tooltip-id="my-tooltip-1" onClick={() => { handleWp(rowData.id) }} className="Btns ButtonPrimaryLight BtnWp"><Icon icon="ic:outline-wordpress"></Icon></button> 
                                        : null }
                                        {(isAdmin() || user?.role === "jornalista") && rowData.is_wordpress ==  true ?
                                            <button data-tooltip-id="my-tooltip-2" className="Btns ButtonPrimaryLight BtnWp disable"><Icon icon="ic:outline-wordpress"></Icon></button> 
                                         : null }
                                    </>
                                :  null}
                                {intI7 ? 
                                    <>
                                        {(isAdmin() || user?.role === "jornalista") && rowData.is_i7 == false || rowData.is_i7 == "" ?
                                            <button data-id={rowData.id} data-tooltip-id="my-tooltip-1" onClick={() => { handleI7(rowData.id) }} className="Btns ButtonPrimaryLight BtnI7"><img src={Iconi7}  alt="Writer" /></button> 
                                        : null }
                                        {(isAdmin() || user?.role === "jornalista") && rowData.is_i7 ==  true ?
                                            <button data-tooltip-id="my-tooltip-2" className="Btns ButtonPrimaryLight BtnI7 disable"><img src={Iconi7disable} alt="Writer" /></button> 
                                         : null }
                                    </>
                                :  null}
                                    
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>
            </div>
        </div>
        <ReactTooltip id="my-tooltip-1" place="bottom" content="Publicar" />
        <ReactTooltip id="my-tooltip-2" place="bottom" content="Já publicado" />
        </>
    );
}
export default Tabela;

