import React, { useState } from "react";

import loading from './img/loading.gif';

function LoadingPost() {
    const [textLoadding, setTextLoadding] = useState();

    function init() {
        setTextLoadding("Revisando o SEO");
        setTimeout(() => {
            setTextLoadding("Estruturando a ordem do texto");
            setTimeout(() => {
                setTextLoadding("Criando sugestões de título");
                setTimeout(() => {
                    setTextLoadding("Organizando os parágrafos");
                    setTimeout(() => {
                        setTextLoadding("Revisando regras de concordância");
                        setTimeout(() => {
                            setTextLoadding("Revisando semântica");
                            setTimeout(() => {
                                setTextLoadding("Organizando pontuação e espaçamento de texto");
                                setTimeout(() => {
                                    setTextLoadding("Corrigindo erros gramaticais");
                                    setTimeout(() => {
                                        setTextLoadding("Auditando fontes e citações");
                                        setTimeout(() => {
                                            setTextLoadding("Finalizando o esboço do artigo");
                                        }, 2000);
                                    }, 2000);
                                }, 2000);
                            }, 2000);
                        }, 2000);
                    }, 2000);
                }, 2000);
            }, 2000);
        }, 2000);
    }


    React.useEffect(() => {
        init();
    }, []);
    return (

        <>
            <div className="LoadingPost">
                <i><img src={loading} alt="loading" /></i>
                <h3>Falta pouco!</h3>
                <h4>{textLoadding}</h4>
                {/* <h5>Tempo estimado 00:45</h5> */}
            </div>
        </>
    );
}

export default LoadingPost;
