
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { Dropdown } from 'primereact/dropdown';
import { Icon } from '@iconify/react';
import { TagsInput } from "react-tag-input-component";
import { useAuth } from '../../context/useAuth';
import api from "../../services/api";

// components
import Navbar from '../../components/Navbar';
import ShowPassword from '../../components/ShowPassword';

// styles
import './Config.scss';
import Alerts from "../../components/Alerts";

function Config() {
    const { isAdmin, user, updateUser } = useAuth();
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const [selectedTime, setSelectedTime] = useState(null);
    const [config, setConfig] = useState(0);
    const [configData, setConfigData] = useState({});
    const [configUsers, setConfigUsers] = useState({});
    const [accountId, setAccountId] = useState(0);
    const [accountName, setAccountName] = useState("");
    const [userId, setUserId] = useState(0);
    const [settingsId, setSettingsId] = useState(0);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [firstTime, setFirstTime] = useState(true);

    const [isVisible, setIsVisible] = useState(false)
    const [isTitle, setTitle] = useState('')
    const [initDescription, setInitDescription] = useState('')
    const [initType, setInitType] = useState('')

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsVisible(false)
            }, 2000)
        }
    }, [isVisible])

    const placeholders = [
        { label: 'Por palavra', value: 'word' },
        { label: 'Por artigo', value: 'time' }
    ];


    useEffect(() => {
        if (typeof user !== 'undefined' && accountId === 0 && userId === 0) {
            //console.log("Dados do usuário 1:", user)
            setAccountId(user.account);
            setUserId(user.id);
        }

        if (userId !== 0 && Object.keys(configUsers).length === 0) {
            // console.log("ID do usuário:", userId)
            api.get(`users/${userId}`, { withCredentials: true }).then(response => {
                setConfigUsers(response.data);
                // handleAlert("Sucesso", "Sucesso ao salvar os dados", "success")
                //console.log("Dados do usuário 2:", response.data);
            }).catch(error => {
                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
                console.error("Erro ao obter dados do usuário:", error);
            });
        }

        if (accountId !== 0 && Object.keys(configData).length === 0 && firstTime) {
            setFirstTime(false);
            api.get(`settings/${accountId}`, { withCredentials: true })
                .then(response => {
                    //console.log("Dados de configuração:", response.data);
                    setConfigData(response.data);
                    setSettingsId(response.data._id)
                    setSelectedTime(response.data.type_time)
                    getAccountData()
                })
                .catch(error => {
                    console.error("Erro ao obter dados de configuração:", error);
                });
        }
    }, [config, configData, configUsers, user, userId, accountId]);

    const getAccountData = async () => {
        api.get(`accounts/${accountId}`, { withCredentials: true })
            .then(response => {
                setAccountName(response.data.name)
            })
            .catch(error => {

                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
                console.error("Erro ao obter dados de configuração:", error);
            });
    }

    const handleAlert = (title, description, type) => {
        setIsVisible(true)
        setTitle(title)
        setInitDescription(description)
        setInitType(type)
    }

    const handleAccountChange = (e) => {
        setAccountName(e.target.value)
    }


    const handleInputChange = (event, field) => {
        setConfigData(prevData => ({
            ...prevData,
            [field]: event.target.value
        }));
    };

    const handleInputChangeUsers = (event, field) => {
        setConfigUsers(prevData => ({
            ...prevData,
            [field]: event.target.value
        }));
    };

    const handleDropdownChange = (event) => {

        setSelectedTime(event.value);
        setConfigData(prevData => ({
            ...prevData,
            type_time: event.value
        }));
    };

    const updateAccountName = async () => {
        await api.put(`/accounts/${accountId}`, { name: accountName }, { withCredentials: true })
            .then(response => {
                //console.log("Conta atualizada com sucesso:", response.data)
                handleAlert("Sucesso", "Sucesso ao salvar os dados", "success")
            })
            .catch(error => {
                console.error("Erro ao obter dados de configuração:", error);
                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
            });
    }


    const handleSaveUserData = () => {

        try {
            api.put(`users/${userId}`, configUsers, { withCredentials: true }).then(response => {
                //console.log("Dados do usuário salvos com sucesso:", response.data);
                updateAccountName()
                updateUser(configUsers.name, 'nome')
                handleAlert("Sucesso", "Sucesso ao salvar os dados", "success")
            }).catch(error => {
                console.error("Erro ao salvar dados do usuário:", error);
                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
            });

            if (currentPassword && newPassword && confirmPassword) {
                saveNewPasswordData()
            }


        } catch (err) {
            handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
        }

    };

    const saveSettingsData = async () => {
        await api.put(`settings/${settingsId}`, configData, { withCredentials: true })
            .then(async (response) => {
                // console.log("Settings salvos com sucesso:", response.data);
                handleAlert("Sucesso", "Sucesso ao salvar os dados", "success")
            })
            .catch(error => {
                console.log("Erro: " + configData);
                console.error("Erro ao obter dados de configuração:", error);
                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
            });
    };


    const handleCurrentPass = (e) => {
        setCurrentPassword(e.target.value)
    }
    const handleNewPass = (e) => {
        setNewPassword(e.target.value)
    }
    const handleConfirmPass = (e) => {
        setConfirmPassword(e.target.value)
    }

    const saveNewPasswordData = async () => {

        const passData = {
            password: currentPassword,
            newPassword,
            email: user?.email
        }

        await api.post(`/authentication/new-password-logged`, passData, { withCredentials: true })
            .then(async (response) => {
                handleAlert("Sucesso", "Sucesso ao salvar os dados", "success")
            })
            .catch(error => {
                handleAlert("Erro", "Erro ao obter dados de configuração", "danger")
                console.error("Erro ao obter dados de configuração:", error);
            });
    };

    const togglePasswordVisibility = (input) => {
        if (input === 'current') {
            setShowCurrentPassword(!showCurrentPassword);
        } else if (input === 'new') {
            setShowNewPassword(!showNewPassword);
        } else if (input === 'confirm') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };


    return (
        <>
            <Navbar />
            {isVisible ?
                <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
            }

            <div className="Content Config">
                <Row>
                    <Col xs={12} sm={12} md={8} lg={12} className='mx-auto'>
                        <div className="Title titlePrimary">
                            Configurações
                            <span>Selecione a opção desejada abaixo</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={12} className='mx-auto'>
                        <Button onClick={() => { setConfig(0) }} className={`Cards ${config === 0 ? 'active' : ''}`}><i><Icon icon="ph:user-bold" /></i> <span>Dados da Conta</span></Button>
                        <Button onClick={() => { setConfig(1) }} className={`Cards ${config === 1 ? 'active' : ''}`}><i><Icon icon="ic:sharp-filter" /></i> <span>Definir Filtros de Feed</span></Button>
                        {isAdmin() && <Button onClick={() => { setConfig(2) }} className={`Cards ${config === 2 ? 'active' : ''}`}><i><Icon icon="uil:setting" /></i> <span>Configuração de Edição</span></Button>}
                        {isAdmin() && <Button onClick={() => { setConfig(3) }} className={`Cards ${config === 3 ? 'active' : ''}`}><i><Icon icon="octicon:goal-16" /></i> <span>Ajustar Metas</span></Button>}
                    </Col>

                    {config === 0 ?
                        <Col xs={12} sm={12} md={8} lg={12} className='conta'>
                            <Form.Group className="grup">
                                <h2>Dados da Conta</h2>
                                <Row>
                                    <Col xs={12} sm={6} md={8} lg={6} className='dados-conta'>
                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control className='Inputs' type="text" placeholder="Nome" value={configUsers.name || ''} onChange={(e) => handleInputChangeUsers(e, 'name')} />
                                                <Icon icon="ph:user-bold" />
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control className='Inputs' type="text" placeholder="Editora" value={accountName} onChange={(e) => {
                                                    handleAccountChange(e)
                                                }} />
                                                <Icon icon="dashicons:admin-site" />
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control className='Inputs' type="text" placeholder="E-mail" value={configUsers.email || ''} onChange={(e) => handleInputChangeUsers(e, 'email')} />
                                                <Icon icon="line-md:email" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={8} lg={6} className='dados-conta'>
                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control
                                                    className='Inputs'
                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                    placeholder='Senha Atual'
                                                    value={currentPassword}
                                                    onChange={handleCurrentPass}
                                                />
                                                <Icon icon="mdi:password-outline" />
                                                <ShowPassword
                                                    isVisible={showCurrentPassword}
                                                    toggleVisibility={() => togglePasswordVisibility('current')}
                                                />
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control
                                                    className='Inputs'
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    placeholder='Nova Senha'
                                                    value={newPassword}
                                                    onChange={handleNewPass}
                                                />
                                                <Icon icon="mdi:password-outline" />
                                                <ShowPassword
                                                    isVisible={showNewPassword}
                                                    toggleVisibility={() => togglePasswordVisibility('new')}
                                                />
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group className="GroupIcon">
                                            <Form.Label className='Label'>
                                                <Form.Control
                                                    className='Inputs'
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    placeholder='Confirmar Senha'
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPass}
                                                />
                                                <Icon icon="mdi:password-outline" />
                                                <ShowPassword
                                                    isVisible={showConfirmPassword}
                                                    toggleVisibility={() => togglePasswordVisibility('confirm')}
                                                />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={6} md={8} lg={12} className="text-center">
                                        <Button className="Btns ButtonPrimary" onClick={handleSaveUserData}>Salvar Dados</Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        : config === 1 ?
                            <Col xs={12} sm={12} md={12} lg={12} className='filtros'>
                                <Form.Group className="grup">
                                    <h2>Definir Filtros de Feed</h2>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6} className="text-center">
                                            <TagsInput
                                                value={configData.categories || []}
                                                onChange={(tags) => handleInputChange({ target: { value: tags } }, 'categories')}
                                                name="addCategories"
                                                placeHolder="Adicionar Categorias"
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} className="text-center">
                                            <TagsInput
                                                value={configData.relatedSites || []}
                                                onChange={(tags) => handleInputChange({ target: { value: tags } }, 'relatedSites')}
                                                name="addSites"
                                                placeHolder="Adicionar Sites"
                                            />

                                            <TagsInput
                                                value={configData.removeSites || []}
                                                onChange={(tags) => handleInputChange({ target: { value: tags } }, 'removeSites')}
                                                name="removeSites"
                                                placeHolder="Remover Sites"
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={12} className="text-center">
                                            <Button className="Btns ButtonPrimary" onClick={saveSettingsData}>Salvar Preferências</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            : config === 2 && isAdmin() ?
                                <Col xs={12} sm={12} md={12} lg={12} className='tempo'>
                                    <Form.Group className="grup">
                                        <h2>Tempo de Revisão</h2>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} className='dados-conta'>
                                                <Form.Group className="GroupIcon">
                                                    <Form.Label className='Label'>
                                                        <div className='Select'>
                                                            <Dropdown value={selectedTime} onChange={handleDropdownChange} options={placeholders} optionLabel="label" placeholder="Selecionar filtro" className='filtro' />
                                                            <Icon icon="icon-park-outline:time" />
                                                        </div>
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} className='dados-conta'>
                                                <Form.Group className="GroupIcon">
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' type="data" placeholder="00:00:00" value={configData.time || ''} onChange={(e) => handleInputChange(e, 'time')} />
                                                        <Icon icon="icon-park-outline:time" />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                                                <Button className="Btns ButtonPrimary" onClick={saveSettingsData}>Salvar Tempo de Revisão</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                :
                                <Col xs={12} sm={12} md={12} lg={12} className='metas'>
                                    <Form.Group className="grup">
                                        <h2>Customização de Metas</h2>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={6} className='dados-conta'>
                                                <Form.Group className="GroupIcon">
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' type="text" placeholder="Porcentagem de Mudança" value={configData.meta_for_posts || ''} onChange={(e) => handleInputChange(e, 'meta_for_posts')} />
                                                        <Icon icon="octicon:goal-16" />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} className='dados-conta'>
                                                <Form.Group className="GroupIcon">
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' type="text" name="overallGoal" placeholder="Meta Geral" value={configData.meta_for_account || ''} onChange={(e) => handleInputChange(e, 'meta_for_account')} />
                                                        <Icon icon="octicon:goal-16" />
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="GroupIcon">
                                                    <Form.Label className='Label'>
                                                        <Form.Control className='Inputs' type="text" name="redactorGoal" placeholder="Meta do Redator" value={configData.meta_for_user || ''} onChange={(e) => handleInputChange(e, 'meta_for_user')} />
                                                        <Icon icon="octicon:goal-16" />
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={12} className="text-center">
                                                <Button className="Btns ButtonPrimary" onClick={saveSettingsData}>Salvar Meta</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                    }
                </Row>
            </div>
        </>
    );
}

export default Config;