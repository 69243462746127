import React , { useState, useEffect }from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Navbar from '../../Navbar';
import Alerts from "../../Alerts";
import './ConfigIntegrations.scss';

import DescIntegration from "../DescIntegration";

import FormIntegrations from '../FormIntegrations';

function ConfigIntegrations() {
    
    const [isVisible, setIsVisible] = useState(false)
    const [isTitle, setTitle] = useState('')
    const [initDescription, setInitDescription] = useState('')
    const [initType, setInitType] = useState('')

    return (
        <>
        <Navbar />
        {isVisible ?
            <Alerts title={isTitle} description={initDescription} type={initType}></Alerts> : <></>
        }

        <div className="Content Config">
            <Row>
                <Col xs={12} sm={12} md={8} lg={12} className='mx-auto'>
                    <div className="Title titlePrimary">
                        Integração WordPress
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={3} sm={3} md={3} lg={6} className='mx-auto'>
                    <DescIntegration />
                </Col>
                <Col xs={3} sm={3} md={3} lg={6} className="mx-auto allign-content">
                    <FormIntegrations />
                </Col>
            </Row>
        </div>
    </>
    );
}
export default ConfigIntegrations;