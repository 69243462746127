import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { Icon } from '@iconify/react';

//components
import Navbar from "../../components/Navbar"
import PostsList from "../../components/Dashboard/PostsList"

//style
import './Dashboard.scss';

import PostsAmount from '../../components/PostsAmount';
import ApprovedPosts from '../../components/ApprovedPosts';
import MonthlyGoal from '../../components/MonthlyGoal';
import PostsVolume from '../../components/PostsVolume';
import TrendingPosts from '../../components/TrendingPosts';
import { useAuth } from '../../context/useAuth';


function Dashboard() {

    const { isAdmin } = useAuth();
    const [intType, setType] = useState(false);

    const toggleType = () => {
        setType(!intType);
    };

    const BossRedator = () => {
        return (<>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                    <Row>
                        {isMobile && 
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="Title titlePrimary">
                                    Dashboard
                                </div>
                            </Col>
                        }
                        <Col xs={6} sm={10} md={8} lg={6}>
                            <PostsAmount></PostsAmount>
                        </Col>
                        <Col xs={6} sm={10} md={8} lg={6}>
                            <ApprovedPosts></ApprovedPosts>
                        </Col>
                        {!isMobile && 
                        <Col xs={12} sm={10} md={8} lg={12}>
                            <MonthlyGoal></MonthlyGoal>
                        </Col>
                        }
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                    <PostsVolume></PostsVolume>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={10} md={8} lg={12}>
                    <PostsList />
                </Col>
            </Row>
        </>)
    }

    return (
        <>
            <Navbar />
            <div className="Content Dashboard">

                <Container fluid>
                {isAdmin() ? 
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className='select-dash'>
                        <button className={`Btns ButtonLight ${intType == false ? "active" : 'disabled'}`} onClick={toggleType}>
                            <Icon icon="streamline:money-graph-bar-product-data-bars-analysis-analytics-graph-business-chart" />
                        </button>
                        <button className={`Btns ButtonLight ${intType == true ? "active" : 'disabled'}`} onClick={toggleType}>
                            <Icon icon="ic:sharp-rss-feed" />
                        </button>
                    </Col>
                </Row>
                : <></> }

                    {isAdmin() ? 
                    <>
                        <div className={`BossRedator ${intType == false ? "show" : 'hide'}`}>
                            <BossRedator  /> 
                        </div>
                        <div className={`TrendingPosts ${intType == true ? "show" : 'hide'}`}>
                            <TrendingPosts  />
                        </div>
                    </>
                    
                    : <></>}



                    {!isAdmin()  ? <TrendingPosts/> : <></>}

                    
                </Container>
            </div>
        </>

    );
}

export default Dashboard;
