import React, { useEffect } from "react";
import { useState } from "react";

import { Icon } from "@iconify/react";

import './FormIntegrations.scss';
import Alerts from "../../../components/Alerts";
import api from "../../../services/api";
import { useAuth } from "../../../context/useAuth";

function FormIntegrations() {
    const { user, loading } = useAuth()

    const [isVisible, setIsVisible] = useState(false);
    const [isTitle, setTitle] = useState('');
    const [initDescription, setDescription] = useState('');
    const [initType, setType] = useState('');

    const [keyAPI, setkeyAPI] = useState()
    const [url, setUrl] = useState()
    const [idWp, setIdwp] = useState()
    const [isSave, setSave] = useState()


    const handleAccount = (v_account) => {
        setkeyAPI(v_account)
    }

    const handleUrl = (v_url) => {
        setUrl(v_url)
    }
    const handleId = (v_id) => {
        setIdwp(v_id)
    }

    async function getUserData(account) {
        await api.get(`/i7/${account}`,
            { withCredentials: true }
        ).then((res) => {
            try {
                if (res) {
                    if (!res.data[0]) {
                        setSave(false)
                    }else{
                        const selectedConfigsWp = res.data[0]
                        handleAccount(selectedConfigsWp.token)
                        handleUrl(selectedConfigsWp.url)
                        handleId(selectedConfigsWp._id)
                        setSave(true)
                    }
                }
                else{
                    setSave(false)
                }
            } catch (err) {
                throw err
            }
        })
    }

    useEffect(() => {
        if (user) {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);

            getUserData(user.account)

        }
    }, [loading, user])

    const handlekeyAPIChange = (event) => {
        setkeyAPI(event.target.value);
    };

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };
    const handleSubmit = async (event) => {
        console.log(user.account);

        const sendRelation = {
            token: keyAPI,
            url: url,
            id_account: user.account
        }

        const InputkeyAPI = document.getElementById('keyAPI');
        const iconkeyAPI = document.getElementById('keyAPIIcon');
        const Inputurl = document.getElementById('url');
        const iconurll = document.getElementById('urlIcon');

        if (InputkeyAPI.value.trim() === '') {
            InputkeyAPI.classList.add('inputWrong');
            iconkeyAPI.classList.add('iconWrong');
             return;
        }
        else{
            InputkeyAPI.classList.remove('inputWrong');
            iconkeyAPI.classList.remove('iconWrong');
        }

        if (Inputurl.value.trim() === '') {
            Inputurl.classList.add('inputWrong');
            iconurll.classList.add('iconWrong');
             return;
        }
        else{
            Inputurl.classList.remove('inputWrong');
            iconurll.classList.remove('iconWrong');
        }

        try {

            await api.post(`/i7/`, sendRelation, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setType('success');
                setTitle('Integração Realizada!');
                setDescription('Seus dados de integração foram salvos');

                setTimeout(() => {
                    //window.location.href = "/integrations"
                }, 4000);
            })

        } catch (err) {

        }
    };

    const handleEdit = async (event) => {

        const sendRelationShip = {
            token: keyAPI,
            url: url,
            id_account: user.account
        }

        const InputkeyAPI = document.getElementById('keyAPI');
        const iconkeyAPI = document.getElementById('keyAPIIcon');
        const Inputurl = document.getElementById('url');
        const iconurll = document.getElementById('urlIcon');

        if (InputkeyAPI.value.trim() === '') {
            InputkeyAPI.classList.add('inputWrong');
            iconkeyAPI.classList.add('iconWrong');
             return;
        }
        else{
            InputkeyAPI.classList.remove('inputWrong');
            iconkeyAPI.classList.remove('iconWrong');
        }

        if (Inputurl.value.trim() === '') {
            Inputurl.classList.add('inputWrong');
            iconurll.classList.add('iconWrong');
             return;
        }
        else{
            Inputurl.classList.remove('inputWrong');
            iconurll.classList.remove('iconWrong');
        }


        try {
      
            await api.put(`/i7/${idWp}`, sendRelationShip, {
                withCredentials: true
            }).then((res) => {
                setIsVisible(true);
                setType('success');
                setTitle('Edição realizada!');
                setDescription('Seus dados de integração foram alterados');

                setTimeout(() => {
                    //window.location.href = "/integrations"
                }, 4000);
            })

        } catch (err) {

        }

    };

    return (

        <div className='form'>
            {isVisible &&
                <Alerts title={isTitle} description={initDescription} type={initType} />
            }
            <div className="information">
                <span>Preencha os campos abaixo para ativar a integração</span>
            </div>
            <form>
                <div className="form-group">
                    <span className='icon'><Icon id='keyAPIIcon' icon="solar:key-outline" /></span>
                    <input type="text" className="form-control" id="keyAPI" placeholder="Chave da API" value={keyAPI} onChange={handlekeyAPIChange} />
                </div>

                <div className="form-group">
                    <span className='icon'><Icon id='urlIcon' icon="dashicons:admin-site" /></span>
                    <input type="text" className="form-control" id="url" placeholder="URL do site" value={url} onChange={handleUrlChange} />
                </div>

                <div className="button">
                    <a href="/integrations" className="btn back">Voltar</a>
                    {!isSave ?
                    <input type="button" onClick={handleSubmit} className="btn create" value="Salvar integração"></input>
                    : <input type="button" onClick={handleEdit} className="btn create" value="Editar integração"></input>
                    }
                </div>

            </form>
        </div>
    );
}
export default FormIntegrations;
