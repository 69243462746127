import { React, useEffect, useState, Fragment } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { useAuth } from '../../../context/useAuth';
import api from '../../../services/api';
import { Dropdown } from 'primereact/dropdown';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";

//Componentes
import AvatarInitials from './avatar';

//Styles
import './List.scss';
import Cookies from "js-cookie";

function List({ filter }) {
  const {user, loading, token, getInitials } = useAuth();
  const [users, setUsers] = useState();

  const handleUsers = (values) => {
    setUsers(values);
  };


  function handlePersonify(id){
    window.localStorage.setItem('personify-user',  Cookies.get('Token')) ;
    api.get(`/users/impersonate/${id}`,
        { withCredentials: true }
    ).then((res) => {
        try {
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 500); 
        } catch (err) {
            throw err
        }
    })
  };

  async function getAccountData(id) {
    await api.get(`/accounts/${id}`,
        { withCredentials: true }
    ).then((res) => {
        try {
          const selectedAccount = res.data.name
          getPostsData(id, selectedAccount)

        } catch (err) {
            throw err
        }
    })
  }


  async function getPostsData(id, selectedAccount) {

    await api.get(`/users/account/${id}`, { withCredentials: true }).then((res) => {
      try {
        let tmp_users = [];
        
        res.data.forEach((c_user) => {
          tmp_users.push({
            id: c_user._id,
            name: c_user.name,
            email: c_user.email,
            Picture: getInitials(c_user.name),
            Status: c_user.active ? 'ativo' : 'inativo',
            accountName: selectedAccount
          });
        });

    
        handleUsers(tmp_users);
      } catch (err) {
        throw err;
      }
    });
  }

  useEffect(() => {
    if (user) {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const id = params.get('id');
        getAccountData(id)
    }
}, [loading, user])

  const filteredUsers = filter ? users?.filter((user) => user.Status === filter.toLowerCase()) : users;

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 6, value: 6 },
        { label: 12, value: 12 },
        { label: 18, value: 18 },
        { label: 24, value: 24 },
        { label: 30, value: 30 },
      ];

      return (
        <Fragment>
          <span className='mx-1' style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Matérias por página:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className='listWriter account'>
      <div className='card'>
        <DataTable
          value={filteredUsers}
          paginatorClassName={'paginator'}
          paginatorTemplate={paginatorTemplate}
          sortField={'date'}
          sortOrder={-1}
          paginator
          rows={6}
        >
          <Column field='id' style={{ width: '0%' }} body={(rowData) => <></>} />
          <Column
            field='Picture'
            bodyClassName='title-column'
            style={{ width: '10%' }}
            body={(rowData) => <AvatarInitials rowData={rowData} />}
          />

          <Column field='accountName' header='Publisher' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column field='name' header='Nome' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column field='email' header='E-mail' bodyClassName='title-column' style={{ width: '20%' }}></Column>

          <Column
            field='Status'
            header='Status'
            style={{ width: '15%' }}
            body={(rowData) => {
              let statusClass = '';
              if (rowData.Status === 'ativo') {
                statusClass = 'status-ativo';
              } else if (rowData.Status === 'inativo') {
                statusClass = 'status-inativo';
              }
              return (
                <span>
                  <span className={statusClass}></span>&nbsp;
                  <span className='status'>{rowData.Status}</span>
                </span>
              );
            }}
          ></Column>

          <Column
            field='Actions'
            header=''
            style={{ width: '30%' }}
            body={(rowData) => {
              return (
                <div className='Actions'>
                  <a  onClick={() => handlePersonify(rowData.id)} className='Btns ButtonPrimaryLight icon'><Icon icon="iconamoon:enter-fill" color="#FFFFFF" /></a>
                  <a href={`/editwriter?id=${rowData.id}`} className='Btns ButtonPrimaryLight'> EDITAR </a>
                </div>
              );
            }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default List;

