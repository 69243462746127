import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import { PencilSquare, Trash, CheckCircle, Hourglass } from 'react-bootstrap-icons';


import ConfirmModal from '../../components/ConfirmModal';
import AddPostModal from "../../components/AddPostModal";
import { orderByDate } from '../../utils/arrayFunctions';
import { dateToLocaleString } from "../../utils/dates";

import api from '../../services/api'

function Posts() {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState('');

    useEffect(() => {
        api.get('/posts')
            .then(response => setPosts(orderByDate(response.data)))
            .catch(error => console.error('Erro na requisição:', error));
    }, []);

    const changePostStatus = (postId, status) => {
        const newPosts = posts.map(post => {
            if (post._id === postId) {
                post.review = status;
            }
            return post;
        });
        setPosts(orderByDate(newPosts));
    };

    const updatePosts = (postId) => {
        const newPosts = posts.filter(post => post._id !== postId);
        setPosts(orderByDate(newPosts));
    };

    const handleEdit = (postId) => {
        navigate(`/edit-post/${postId}`);
    };

    const handleClose = () => setShowModal(false);
    const handleShow = (id) => {
        setShowModal(true);
        setCurrentId(id);
    }

    const handleApprove = (postId) => {
        api.put(`/posts/${postId}`, { review: 'approved' })
            .then(() => changePostStatus(postId, 'approved'))
            .catch(error => console.error('Erro na requisição:', error));
    };

    const handleReprove = (postId) => {
        api.put(`/posts/${postId}`, { review: 'pending' })
            .then(() => changePostStatus(postId, 'pending'))
            .catch(error => console.error('Erro na requisição:', error));
    };


    const handleDelete = (postId) => {
        api.delete(`/posts/${postId}`)
            .then(response => {
                updatePosts(postId);
                handleClose();
            })
            .catch(error => {
                console.error('Erro na requisição:', error);
            });
    };

    return (
        <div className="container mt-3">
            <h1>Posts</h1>
            <AddPostModal />
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Título</th>
                    <th>Data</th>
                    <th>Porcentagem de alteração</th>
                    <th>Revisão</th>
                    <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                {posts.map((post) => (
                    <tr key={post._id}>
                        <td>{post.title}</td>
                        <td>{dateToLocaleString(post.date)}</td>
                        <td>{post.difference_between_text}%</td>
                        <td>
                            {post.review === 'pending'
                            ? <Button variant="success" onClick={() => handleApprove(post._id)}><CheckCircle /></Button>
                            : <Button variant="info" onClick={() => handleReprove(post._id)}><Hourglass /></Button>
                            }
                        </td>
                        <td>
                            <Button variant="primary" onClick={() => handleEdit(post._id)}><PencilSquare /></Button>{' '}
                            <Button variant="danger" onClick={() => handleShow(post._id)}><Trash /></Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <ConfirmModal
                show={showModal}
                handleClose={handleClose}
                title="Confirmação de exclusão"
                text="Tem certeza de que deseja excluir este post?"
                confirmAction={() => handleDelete(currentId)}
            />
        </div>
    );
}

export default Posts;
