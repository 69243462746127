import React, { useEffect } from 'react';
import './Home.scss';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate()

    useEffect(()=>{
        navigate("/dashboard")
    },[])
  
    return (
        <>  
            <Navbar />
        </>

    );
}

export default Home;