import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { Button, Col, Row } from "react-bootstrap";
import Modals from "../../Modals";
import { Icon } from '@iconify/react';
function ViewPost({ onData, sendReview, handleApprovedAndOpenModal, handleRejectedAndOpenModal }) {
    const [postTitleEditor, setPostTitleEditor] = useState();
    const [postTextEditor, setPostTextEditor] = useState();
    const [isActiveModal, setIsActiveModal] = useState(false);

    const [redator, setRedator] = useState('');
    const [fonte, setFonte] = useState('');

    useEffect(() => {
        if (onData) {
            setPostTitleEditor(onData?.title_editor);
            setPostTextEditor(onData?.text_editor);
            setRedator(onData?.id_user ? onData.id_user.name : '');
            setFonte(getFirstSiteName(onData?.original_url));
        }
    }, [onData]);

    const getFirstSiteName = (url) => {
        try {
            const parsedUrl = new URL(url);
            const hostname = parsedUrl.hostname;
            const siteName = hostname.split('.')[0];
            return siteName;
        } catch (error) {
            console.error("Error parsing URL:", error);
            return '';
        }
    };

    const handleCloseModal = () => {
        setIsActiveModal(false);
    };

    return (
        <>
            <div className="Posts">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={12} className="mx-auto">
                        <div className="Review">
                            <Row>
                                <Col xs={12} sm={10} md={8} lg={12} className="mx-auto">
                                    <div className="ReviewTitle">
                                        <Row>
                                            <Col xs={12} sm={10} md={8} lg={7} className="mx-auto">
                                                <ul>
                                                    <li>
                                                        Redator: <strong>{redator}</strong>
                                                    </li>
                                                    <li>
                                                        Fonte: <strong>{fonte}</strong>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col xs={12} sm={10} md={8} lg={5} className='ReviewActions'>
                                                <Button className="Btns ButtonPublic" onClick={handleApprovedAndOpenModal}>Aprovar</Button>
                                                <Button className="Btns ButtonDanger" onClick={handleRejectedAndOpenModal}>Rejeitar</Button>
                                                <Button className="Btns ButtonPrimary" onClick={sendReview}>Revisar</Button>
                                                <a href="/my-articles"><Button className="Btns ButtonLight"><i><Icon icon="icon-park-outline:back"/></i> Voltar</Button></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} sm={10} md={8} lg={12} className="mx-auto">
                                    <div className="PostView">
                                        <h2>{postTitleEditor}</h2>
                                        <div className="text">
                                            <p dangerouslySetInnerHTML={{ __html: postTextEditor }}></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={10} md={8} lg={12} className="mx-auto"></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modals isActiveModal={isActiveModal} handleClose={handleCloseModal} />
        </>
    );
}
export default ViewPost;