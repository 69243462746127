import React, { useEffect, useState } from "react";
import { Icon } from '@iconify/react';
import { ProgressBar } from 'primereact/progressbar';
import { useAuth } from "../../context/useAuth"
import "./MonthlyGoal.scss"
import api from "../../services/api";
import { formatMilenar } from "../../utils/formatFunctions";
import { getLastMonth } from "../../utils/dates";

export default function MonthlyGoal() {

    const { user } = useAuth()

    const [
        goalData, setGoalData
    ] = useState({
        totalPosts: 0,
        goalForAccount: 0,
        goalPercent: 0
    })

   
    const [lastMonthAvg,setLastMonthAvg] = useState()

    async function getAccountGoal() {
        try {
            await api.get(`/goals/account/${user?.account}`, {
                withCredentials: true
            }).then((res) => {
             
                setGoalData(res.data)
            })

            await api.get(`/posts/last/account/${user?.account}`, { withCredentials: true }
            ).then((res) => {
                try {
                    
                    setLastMonthAvg(formatMilenar(res.data.lastMonth))
                } catch (err) {
                    throw err
                }

            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (user) {
            getAccountGoal()
        }
    }, [user])

    return (<>
        <div className="widget-monthlyGoal">
            <div className="box">
                <div className="content-monthlyGoal">
                    <div className="icon-pa">
                        <div className="box">
                            <Icon color={"#0092C9"} icon={"mdi:bullseye"} width={45} height={45}></Icon>
                        </div>
                    </div>
                    <div className="info">
                        <div className="metric">
                            <span className="title">Meta mensal</span>
                            <div className="goal">
                                <span className="current">{formatMilenar(goalData.totalPosts)}</span>
                                <span className="bar"> / </span>
                                <span className="expected">{formatMilenar(goalData.goalForAccount)}</span>
                            </div>
                        </div>
                        <div className="values">
                            <ProgressBar className="progressBarGoal" value={goalData.goalPercent} color="#42C3F3"></ProgressBar>
                        </div>
                    </div>
                </div>
                <div className="created-by-month">
                    <div className="values">
                        <span>
                            <strong>{lastMonthAvg}</strong> em {getLastMonth()}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </>)
}

