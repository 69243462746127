
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { AuthContext, useAuth } from '../../context/useAuth';
import api from '../../services/api';

import './Login.scss';

import LogoLogin from '../../components/LogoLogin';
import Alerts from '../../components/Alerts';
import ShowPassword from '../../components/ShowPassword';
// import Token from '../../components/Token';
import Dashboard from '../Dashboard';
import PasswordRecovery from '../../components/PasswordRecovery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function Login() {

  const [showPassword, setShowPassword] = useState(false);
  const { checkLogin, loading } = useAuth()

  useEffect(() => {
    if (loading) {
      checkLogin()
    }
  }, [loading])

  const navigate = useNavigate()

  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isVisible, setIsVisible] = useState(false);
  const [isTitle, setTitle] = useState('');
  const [initDescription, setDescription] = useState('');
  const [initType, setType] = useState('');

  const handleLogin = async (email, password) => {
    try {

      const response = await api.post('/authentication/login', {
        "email": email,
        "password": password
      },
        {
          withCredentials: true
        });

      if (response.data) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        setIsVisible(true);
        setType('danger');
        setTitle('Credenciais inválidas');
        setDescription('Erro de conexão. Verifique sua conexão de rede.');
      } else {
        setIsVisible(true);
        setType('danger');
        setTitle('Credenciais inválidas');
        setDescription('Verifique os dados e novamente');
      }
    }
  };



  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsVisible(false);

    const usernameInput = document.getElementById('username');
    const passwordInput = document.getElementById('password');
    const iconMail = document.getElementById('mail');
    const iconLock = document.getElementById('lock');

    iconMail.classList.remove('iconWrong');
    iconLock.classList.remove('iconWrong');
    usernameInput.classList.remove('usernameWrong');
    passwordInput.classList.remove('passwordWrong');

    if (usernameInput.value.trim() === '') {
      usernameInput.classList.add('usernameWrong');
      iconMail.classList.add('iconWrong');
      return;
    }

    if (passwordInput.value.trim() === '') {
      passwordInput.classList.add('passwordWrong');
      iconLock.classList.add('iconWrong');
      return;
    }

    if (usernameInput.value !== '' && passwordInput.value !== '') {
      //setIsLoggedIn(true);
      handleLogin(usernameInput.value, passwordInput.value)
    }
    else {
      usernameInput.classList.add('usernameWrong');
      passwordInput.classList.add('passwordWrong');
      iconMail.classList.add('iconWrong');
      iconLock.classList.add('iconWrong');
    }
  };



  if (isLoggedIn) {
    window.location.href = window.location.origin + "/dashboard"
  }

  const handlePasswordRecovery = () => {
    setShowPasswordRecovery(true);
  };
  if (showPasswordRecovery) {
    return <PasswordRecovery />;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="page-login">
      {isVisible &&
        <Alerts title={isTitle} description={initDescription} type={initType} />
      }
      <div className="container-fluid">
        <div className='row'>
          <div className='col-12' id='logo'>
            <LogoLogin />
          </div>
          <div className='col-12' id='forms'>
            <div className='form'>
              <div className='title'>
                <h4> Faça login na sua conta <span> BluWi</span> </h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <span className='icon' id="mail">
                    <Icon id='mail' icon="line-md:email" />
                  </span>
                  <input
                    className='login'
                    type="text"
                    id="username"
                    placeholder='Digite seu email'
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </div>
                <div>
                  <span className="icon" id="lock">
                    <Icon id="lock " icon="bx:lock" />
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <ShowPassword isVisible={showPassword} toggleVisibility={togglePasswordVisibility} />
                </div>

                <div onClick={handlePasswordRecovery} className="passwordRecovery">
                  Esqueceu a sua senha?
                </div>
                <button className='Btns ButtonPrimary' type="submit">Acessar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;