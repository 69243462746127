import { Icon } from "@iconify/react";
import React from "react";
import "./Content.scss"
import { useState } from "react";
import { useEffect } from "react";

export default function CardRowContent({ title, content, id }) {

    const [titleComp, setTitleComp] = useState('')
    const [contentComp, setContentComp] = useState('')



    function textLimit(text, limit) {
        if (text.length <= limit) {
            return text
        }
        const limitedText = text.slice(0, limit)
        return `${limitedText.replaceAll("<br/>","").replaceAll("<br>","")}...</p>`
    }

    useEffect(() => {
        if (title && content) {
            setTitleComp(textLimit(title, 120))
            setContentComp(textLimit(content, 300))
        }
    }, [title, content])

    return (
        <div className={"card-content-visualization"}>
            <div className={"card-row-content"}>
                <div className={"icon-row"}>
                    <div className={"icon-box"}>
                        <Icon width={26} icon={"fa6-solid:clock-rotate-left"}></Icon>
                    </div>
                </div>
                <div className={"info-row"}>
                    <div className={"info-row-title"}>{titleComp}</div>
                    <div className={"info-row-content"} dangerouslySetInnerHTML={{ __html: contentComp }}>
                      
                    </div>
                </div>
            </div>
            <div className={"card-row-actions"}>
                <a className={"Btns ButtonLight"} href={`/reviewposts/${id}`}>VISUALIZAR</a>
            </div>
        </div>
    )
}