import React, {useState} from "react";
import { Col, Row } from "react-bootstrap";

//components
import Navbar from '../../Navbar';
import Filter from '../Filter';
import Button from '../Button';
import ListAccount from '../ListAccountWriter';

//styles
import './Accounts.scss';

function AccountsList() {
    const [filter, setFilter] = useState(null);
  
    const handleFilterChange = (value) => {
      setFilter(value);
    };
  
    return (
      <>
        <Navbar />
        <div className='Content Accounts'>
          <Row  className='content'>
              <Col xs={12} sm={6} md={8} lg={6} className='mx-auto'>
                <div className='Title titlePrimary'>Usuários do Publisher</div>
              </Col>
              <Col xs={12} sm={6} md={8} lg={6} className='mx-auto'>
                <div className='actions'>
                  <Filter onFilterChange={handleFilterChange} />
                  <Button />
                </div>
              </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='List'>
                <ListAccount filter={filter} />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default AccountsList;

